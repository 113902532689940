import Form from 'react-bootstrap/Form';

function CheckExample(props) {
    const { label,onChange,checked} =props
    return (
        <Form>
                <div className="mb-3">
                    <Form.Check
                        type="checkbox"
                        label={label}
                        checked={checked}
                        onChange={onChange}
                    />

                </div>
        </Form>
    );
}

export default CheckExample;