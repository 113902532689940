import Styled from 'styled-components';
const ItemConditionWrapper = Styled.div`
.list{
    list-style:disc;
    padding-left:24px;
}
.terms-table-header{
    tr{
        td{
            background-color: #1889fa;
    border-bottom: 1px solid #ffffff;
    border-left: 1px solid #ffffff;
    border-right: 1px solid #ffffff;
    border-top: 1px solid #ffffff;
    vertical-align: top;
    width: 187px;
    font-weight:600;
    .text{
        font-family: Comic Sans MS,cursive; 
        font-size: 11pt;
        color:#fff;
        text-align:center;
        padding:8px 0;
    }
        }
    }
}
.terms-table-body{
    tr{
        td{
            background-color: #effaff;
    border-bottom: 1px solid #ffffff;
    border-left: 1px solid #ffffff;
    border-right: 1px solid #ffffff;
    border-top: none;
    vertical-align: top;
    width: 187px
    color:#093664;
    padding:12px 4px;
        }
    }
}
`
export default ItemConditionWrapper;