import Styled from 'styled-components';

const FooterWrapper = Styled.div`
    background-color:#5e5f61;
    padding:30px 0;
`
const ListWrapper = Styled.div`
    h5{
        color:#c1da0b;
        font-size: 20px;
    }
   ul{
 li{
          color:${({ theme }) => theme.colors.listColor};
        padding-bottom:8px;
        cursor:pointer;
        &:hover{
                 color:${({ theme }) => theme.colors.onPrimary};
        }
    }
   }
`
const CopyRight = Styled.div`
background-color:#9e9e9e;
padding:40px;
text-align:center;
color: #fff;
`
export { FooterWrapper, ListWrapper, CopyRight }