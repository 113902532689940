import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Logo from '../../static/images/newlogo.png';
import HeaderWrapper from './style';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';

import { ClearState } from '../../redux/slice/authSlice';
import { useDispatch, useSelector } from 'react-redux';

function Header() {
    const dispatch = useDispatch();
    const { token, email } = useSelector(state => state.auth);
    return (
        <HeaderWrapper>
            {['lg'].map((expand) => (
                <Navbar key={expand} expand={expand} className="mb-3">
                    <Container>
                        <Navbar.Brand href="#"><img src={Logo} alt="no-logo" width="300px" /></Navbar.Brand>
                        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                        <Navbar.Offcanvas
                            id={`offcanvasNavbar-expand-${expand}`}
                            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                            placement="end"
                        >
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                    {/* Offcanvas */}
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <Nav className="justify-content-end flex-grow-1 pe-3">
                                    <Nav.Link href="/">Home</Nav.Link>
                                    {token && <Nav.Link href="/my-account">My Account</Nav.Link> }
                                    {token && <Nav.Link href="/sell">Sell</Nav.Link>}
                                    {/* <Nav.Link href="/dispose-books">Dispose Books</Nav.Link> */}
                                    <Nav.Link href="#action4">Blog</Nav.Link>
                                    <Nav.Link href="/contact-us">Contact Us</Nav.Link>
                                    {!token && <Nav.Link href="/register">Register</Nav.Link>}
                                    {!token &&<Nav.Link href="/login">Login</Nav.Link>}
                                    {token && <Nav.Link href="/login" onClick={() => dispatch(ClearState())}>LogOut</Nav.Link>}
                                    <a href="/sell" style={{marginLeft: '10px', marginTop: '2px', cursor: 'pointer'}}>
                                        <ShoppingBasketIcon />
                                    </a>
                                </Nav>
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </Container>
                </Navbar>
            ))}
            </HeaderWrapper>
    );
}

export default Header;
