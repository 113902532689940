import Styled from 'styled-components';

const MultiSelectWrapper = Styled.div`
form{
  position: relative;
  .form-control{
    padding: 11px;
  }
  .multi-select-options{
    display: block;
    width: 100%;
    padding: 11px;
    cursor: pointer;
    li{
      padding: 8px 0;
      label{
        display: flex;
        align-items: center;
      }
      input[type="checkbox"] {
        margin-right: 6px;
      }
    }
  }
}
`
export default MultiSelectWrapper;