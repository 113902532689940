import React, { useEffect, useState } from 'react';
import {Box} from '@mui/material'
import { Container, Row, Col } from 'react-bootstrap';
import IndexImage from '../../static/images/amico.png';
import Pana from '../../static/images/pana.png';
import Bro from '../../static/images/bro.png';
import Rafiki from '../../static/images/rafiki.png';
import Cash from '../../static/images/cash.png';
import { HomeWrapper, ImagesSection, VideoSection, CommentSection } from './style'
import Input from '../../component/input/index';
import Button from '../../component/button/index';
import { BiCheck } from 'react-icons/bi';
import { BsFillChatRightQuoteFill } from 'react-icons/bs';
import "../../../node_modules/video-react/dist/video-react.css";
import Slider from '../../component/slider';


import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { GetCodesList } from '../../redux/slice/promoCodesSlice';
import { SetState } from '../../redux/slice/getProductSlice';

const Index = () => {
    const [isbn, setIsbn] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { list: listPromo } = useSelector((state) => state.promoCode) || {};
    const details = [{
        img: Pana,
        caption: "Scan or enter the barcodes to get instant price offers"
    },
    {
        img: Bro,
        caption: "Complete the entire trade and pack your unwanted items"
    },
    {
        img: Rafiki,
        caption: "Use our courier for home collection or drop off services to send your items."
    },
    {
        img: Cash,
        caption: "Get paid via bank transfer, Paypal, or cheque."
    },]
    const list = ["Enter barcode or ISBN", "Get an instant valuation", "Send us your items for free", "Get paid!"]
    const commentSection=[{
        paragraph: "Their customer service was a huge help with arranging for my books to be picked up. It was hassle- free and my payment was sorted quickly.",
        caption:"V. Daniel, Oxford"
    },
        {
            paragraph: "Thank you for sending a courier to pick up my books on time. I enjoyed doing business with the your team and would definitely return to do more in future!",
            caption: "A. Naomi, Leeds"
        }]

    const moveToSellPage = () => {
        dispatch(SetState({ field: 'searchValue', value: isbn }));
        if (isbn) {
            navigate(`/sell`)
        }
    }

    useEffect(() => {
        dispatch(GetCodesList())
    }, [])
    return (
        <>

        <Slider listPromo={listPromo} />
            <HomeWrapper>
            <Container>
                    <Row className="items-center">
                        <Col lg={6}>
                            <h1 className='mb-2 text-lg-start text-center'>Sell your books for cash!</h1>
                            <div className='mb-2'>
                                <p className='py-3 text-lg-start text-center'>We'll also buy your CDs, DVDs, and games. <br/> Get an INSTANT cash offer now!</p>
                                <div className='pt-3'>
                                    <Input placeholder="Enter Your Book's ISBN" value={isbn} onChange={(e) => setIsbn(e.target.value)} onKeyPress={moveToSellPage} />
                                </div>
                                <Button onClick={() => moveToSellPage()}>
                                    Get Value
                                </Button>
                            </div>
                        </Col>
                        <Col md={6} className='ms-lg-auto m-auto'>
                            <div className='py-lg-4 pt-5'>
                                <img src={IndexImage} alt="no-product" />
                            </div>
                        </Col>
                    </Row>
            </Container>
            </HomeWrapper>
            <ImagesSection>
                <Container>
                    <h2 className='mb-5'>Declutter and recyle your books and cds.</h2>
                    <Row>
                        {details.map((list) => {
                            return (
                                <Col lg={3} md={6} sm={6} xs={12} className='mb-4'>
                                    <Box mb={1} className='images-box '>
                                        <img src={list.img} alt="no-data" width="100%" />
                                        <p>{list.caption}</p>
                                    </Box>
                                </Col>
                            )

                        })}
                    </Row>
                </Container>
            </ImagesSection>
            <VideoSection>
                <Container>
                    <div className='px-2'>
                        <Row>
                            <Col lg={6}>
                                <div className='d-flex justify-content-center flex-column h-100 pb-4' >
                                    <h4 className='mb-2'>Easy, Quick & Stress-free.</h4>
                                    <h2 className='mb-2'>HOW IT WORKS</h2>
                                    <ul className='home-list'>
                                        {list.map((list) => <li className='mb-2'><BiCheck />{list}</li>)}
                                    </ul>
                                </div>
                            </Col>
                            <Col lg={6} >
                                <video controls className='video-section' >
                                    <source src="https://video-directory11.s3.amazonaws.com/fmb.mp4" type="video/mp4" />
                                </video>
                            </Col>

                        </Row>
                    </div>
                </Container>
            </VideoSection>
            <CommentSection>
                <div>
                    <Container>
                        <div className='mb-5'>
                            <h1 className='text-center'>In the last year, our customers have made an average of <strong>£30</strong> by selling their items to us.</h1>
                        </div>
                        <Row className='justify-content-center'>
                            {commentSection.map((comment, index)=>{
                                return(
                                    <Col xl={6}>
                                        <div className='comment-box mb-4'>
                                            <div>
                                                <BsFillChatRightQuoteFill />
                                            </div>
                                            <div>
                                                <p>{comment.paragraph} </p>
                                                {!index ? <><p style={{marginBottom: '0rem'}}><br/></p></> : ''}
                                                <caption>{comment.caption}</caption>
                                            </div>
                                        </div>
                                    </Col>
                                )
                            })}
                            
                        </Row>
                    </Container>
                </div>
            </CommentSection>
        </>
    )
}

export default Index
