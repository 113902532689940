import Styled from 'styled-components';
const ListWrapper = Styled.div`
font-size:18px;
ul{
    padding-left:24px;
}
p{
    padding-left:24px;   
}
.list-style-disc{
    list-style:disc;
    padding-left:24px;
}
.list-style-circle{
    list-style:circle; 
    padding-left:16px;
}
.list-style-square{
    list-style:square;  
    padding-left:24px;   
}
li{
    font-size:18px;
    color:#212529;
    line-height:24px;
    margin-bottom:24px;
}
table{
    width:100%;
}
a{
    color:#0000FF;
    cursor:pointer;
}
.terms-table-header{
    tr{
        td{
            background-color: #1889fa;
    border-bottom: 1px solid #ffffff;
    border-left: 1px solid #ffffff;
    border-right: 1px solid #ffffff;
    border-top: 1px solid #ffffff;
    vertical-align: top;
    width: 187px;
    .text{
        font-family: Comic Sans MS,cursive; 
        font-size: 11pt;
        color:#fff;
        text-align:center;
        padding:8px 0;
    }
        }
    }
}
.terms-table-body{
    tr{
        td{
            background-color: #effaff;
    border-bottom: 1px solid #ffffff;
    border-left: 1px solid #ffffff;
    border-right: 1px solid #ffffff;
    border-top: none;
    vertical-align: top;
    width: 187px
    color:#093664;
    padding:12px 4px;
        }
    }
}
`
export default ListWrapper;
