import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useNavigate } from 'react-router-dom';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Logo from '../../static/images/newlogo.png';
import { HeaderWrapper, AuthWrapper, StyledNavLink } from './style';
import { Table, Row, Col, Form } from "react-bootstrap";
import { Box, Link, Stack } from "@mui/material";
import Button from '../button/index';
import Input from '..//input/index';
import Checkbox from '../checkbox/index';
import Accordion from 'react-bootstrap/Accordion';
import AccountWrapper from '../../container/myAccount/style';
import MultiSelectInput from '../multiSelect';

import { useDispatch, useSelector } from 'react-redux';
import { GetOrders } from '../../redux/slice/getProductSlice';
import { GetUsers } from '../../redux/slice/authSlice';
import { CreateCode, GetCodesList, UpdateCode } from '../../redux/slice/promoCodesSlice';
import { ClearState } from '../../redux/slice/authSlice';

const ProductDetail = (props, key) => {
    const { info } = props;
    return (
        <Accordion>
            <Accordion.Item eventKey={key}>
                <Accordion.Header>Info</Accordion.Header>
                <Accordion.Body>
                    <Box sx={{ border: "1px solid #dfe1e5", borderRadius: "3px" }} p={3}>
                        <Table className='data-table'>
                            <tbody>
                                {info?.map((data, key) => {
                                    return (
                                        <tr>
                                            <td style={{ width: "30px" }}><img width="50px" height="50px" src={data?.imageUrl} alt="no-book" /></td>
                                            <td style={{ width: "180px" }}><Box className="w-100" fontSize="16px" color="#093664">
                                                {data?.title}</Box>
                                                <Box component="span" color="#093664" fontSize="14px">{data?.asin}</Box></td>
                                            <td className='text-end' style={{ width: "20px" }}><Box fontWeight="600" fontSize="18px" >£{data?.amount || "N/A"}</Box></td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </Box>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}

function Header() {
    const dispatch = useDispatch();
    const [renderOrders, setRednderOrders] = useState([]);
    const [apply, setApply] = useState('all');
    const [users, setUser] = useState([]);
    // const [adminSection, setAdminSection] = useState(localStorage.getItem('page'));
    const [adminSection, setAdminSection] = useState(() => {
        return localStorage.getItem('page') ? parseInt(localStorage.getItem('page')) : 0;
    });

    const { err, loading, orders } = useSelector((state) => state.product) || {};
    const { userList = [] } = useSelector((state) => state.auth) || {};
    const { list } = useSelector((state) => state.promoCode) || {};
    const [detail, setDetails] = useState({
        codeNumber: '',
        startDate: new Date(),
        expiry: new Date(),
        apply,
        discount: '',
        description: ''
    });
    useEffect(() => {
        setRednderOrders(orders);
    }, [orders])

    const createPromoCode = () => {
        console.log('users for promo code => ', users);
        dispatch(CreateCode({
            entry: { ...detail, apply },
            users
        })).then((data) => {
            dispatch(GetCodesList(true))
            console.log('data =>', data);
        })
    }

    const changeCodeStatus = (_id, status) => {
        dispatch(UpdateCode({ _id, status }))
            .then(() => dispatch(GetCodesList(true)))
    }

    const mapPaymentInfo = (item) => {
        const { paymentDetail: {
            paymentMethod,
            swiftCode,
            accountNumber,
            name,
            email
        } } = item;
        return (
            <>
                {paymentMethod ? <p>Payment Method: {paymentMethod}</p> : ''}
                {swiftCode ? <p>Swift Code: {swiftCode}</p> : ''}
                {accountNumber ? <p>Account Number: {accountNumber}</p> : ''}
                {name ? <p>Name: {name}</p> : ''}
                {email ? <p>Email: {email}</p> : ''}
            </>
        )
    }

    useEffect(() => {
        dispatch(GetOrders());
        dispatch(GetCodesList(true));
        dispatch(GetUsers());
    }, []);
    return (
        <>
            <HeaderWrapper>
                {['md'].map((expand) => (
                    <Navbar key={expand} expand={expand} className="mb-3">
                        <Container>
                            <Navbar.Brand href="#"><img src={Logo} alt="no-logo" width="300px" /></Navbar.Brand>
                            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                            <Navbar.Offcanvas
                                id={`offcanvasNavbar-expand-${expand}`}
                                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                                placement="end"
                            >
                                <Offcanvas.Header closeButton>
                                    <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                        {/* Offcanvas */}
                                    </Offcanvas.Title>
                                </Offcanvas.Header>
                                <Offcanvas.Body>
                                    <Nav className="justify-content-end flex-grow-1 pe-3">
                                        <StyledNavLink
                                            href="/users"
                                            className={adminSection === 1 ? 'active' : ''}
                                            onClick={() => {
                                                setAdminSection(1);
                                                localStorage.setItem('page', 1);
                                            }}
                                        >
                                            Users
                                        </StyledNavLink>

                                        <StyledNavLink
                                            href="/orders"
                                            className={adminSection === 0 ? 'active' : ''}
                                            onClick={() => {
                                                setAdminSection(0);
                                                localStorage.setItem('page', 0);
                                            }}
                                        >
                                            Orders
                                        </StyledNavLink>

                                        <StyledNavLink
                                            href="/settings"
                                            className={adminSection === 2 ? 'active' : ''}
                                            onClick={() => {
                                                setAdminSection(2);
                                                localStorage.setItem('page', 2);
                                            }}
                                        >
                                            Settings
                                        </StyledNavLink>

                                        <StyledNavLink
                                            href="/login"
                                            onClick={() => dispatch(ClearState())}
                                        >
                                            LogOut
                                        </StyledNavLink>
                                    </Nav>
                                </Offcanvas.Body>

                            </Navbar.Offcanvas>
                        </Container>
                    </Navbar>
                ))}
            </HeaderWrapper>

            {adminSection === 0 && (
                <AccountWrapper>
                    {/* Orders Section */}
                    <Box className="dashboard-table" style={{ height: '85vh' }}>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>Trade ID</th>
                                    <th>Trade Amount</th>
                                    <th>Trade Date</th>
                                    <th>Trade Status</th>
                                    <th>Payment Information</th>
                                    <th>View Details</th>
                                </tr>
                            </thead>
                            <tbody>
                                {renderOrders?.map((item) => (
                                    <tr key={item.transactionId}>
                                        <td>#{item.transactionId}</td>
                                        <td>£{item.totalAmount}</td>
                                        <td>{item.createdAt}</td>
                                        <td>Pending</td>
                                        <td>{mapPaymentInfo(item)}</td>
                                        <td>
                                            <ProductDetail info={item.items} />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Box>
                </AccountWrapper>
            )}

            {adminSection === 1 && (
                <AccountWrapper>
                    {/* Users Section */}
                    <Box className="dashboard-table" style={{ height: '85vh' }}>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Role</th>
                                    <th>Phone No</th>
                                    <th>Address</th>
                                    <th>Postal Code</th>
                                    <th>Payment Method</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {userList?.map((item) => (
                                    <tr key={item._id}>
                                        <td>{item.name || '-'}</td>
                                        <td>{item.email || '-'}</td>
                                        <td>{item.role || '-'}</td>
                                        <td>{item.phoneNumber || '-'}</td>
                                        <td>{item.address || '-'}</td>
                                        <td>{item.postalCode || '-'}</td>
                                        <td>{item.paymentMethod || '-'}</td>
                                        <td>{item.status || '-'}</td>

                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Box>
                </AccountWrapper>
            )}

            {adminSection === 2 && (
                <Container>
                    <AuthWrapper>
                        <div className="text-center mb-5">
                            <h2>Create Promo Codes</h2>
                        </div>
                        <Row className="justify-content-center">
                            <Col lg={10} md={10}>
                                <Row className="p-0 align-items-center justify-content-center mb-5">
                                    {/* Promo Code Creation Form */}
                                    <Col md={6} sm={12} xs={12}>
                                        <Input
                                            name="Promo code"
                                            label="Promo code"
                                            value={detail.codeNumber}
                                            onChange={(e) =>
                                                setDetails({
                                                    ...detail,
                                                    codeNumber: e.target.value,
                                                })
                                            }
                                        />
                                    </Col>
                                    <Col md={6} sm={12} xs={12}>
                                        <Input
                                            name="Discount Percent"
                                            type="number"
                                            label="Discount Percent"
                                            value={detail.discount}
                                            onChange={(e) =>
                                                setDetails({
                                                    ...detail,
                                                    discount: e.target.value,
                                                })
                                            }
                                        />
                                    </Col>
                                    <Col md={6} sm={12} xs={12}>
                                        <Form.Label>Start Date</Form.Label>
                                        <Form.Group controlId="duedate" className="pb-3">
                                            <Form.Control
                                                type="date"
                                                name="Start Date"
                                                placeholder="Start date"
                                                value={detail.startDate}
                                                onChange={(e) =>
                                                    setDetails({
                                                        ...detail,
                                                        startDate: e.target.value,
                                                    })
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} sm={12} xs={12}>
                                        <Form.Label>End Date</Form.Label>
                                        <Form.Group controlId="duedate" className="pb-3">
                                            <Form.Control
                                                type="date"
                                                name="Expiry Date"
                                                placeholder="Expiry date"
                                                value={detail.expiry}
                                                onChange={(e) =>
                                                    setDetails({
                                                        ...detail,
                                                        expiry: e.target.value,
                                                    })
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={12} sm={12} xs={12}>
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            value={detail.description}
                                            onChange={(e) =>
                                                setDetails({
                                                    ...detail,
                                                    description: e.target.value,
                                                })
                                            }
                                            rows={3}
                                        />
                                    </Col>
                                    <Col md={12} sm={12} xs={12}>
                                        <div className="mt-2"></div>
                                    </Col>
                                    <Col xs={12}>
                                        <Form.Check
                                            type="switch"
                                            id="custom-switch"
                                            checked={apply === 'all'}
                                            onChange={() => setApply('all')}
                                            label="All users"
                                        />
                                        <div className="mt-2"></div>
                                    </Col>
                                    <Col xs={12}>
                                        <Form.Check
                                            type="switch"
                                            id="custom-switch"
                                            checked={apply === ''}
                                            onChange={() => setApply('')}
                                            label="Select Users"
                                        />
                                        <div className="mt-2"></div>
                                    </Col>
                                    <Col md={12} sm={12} xs={12}>
                                        <MultiSelectInput
                                            userList={userList}
                                            setUser={setUser}
                                            disabled={apply === 'all'}
                                        />
                                    </Col>
                                    <Col md={12} sm={12} xs={12}>
                                        <Button
                                            size="lg"
                                            width="100%"
                                            bottom="16px"
                                            onClick={() => createPromoCode()}
                                        >
                                            Create
                                        </Button>
                                    </Col>

                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Sr.No</th>
                                                <th>Code Number</th>
                                                <th>Validity</th>
                                                <th>Discount</th>
                                                <th>Description</th>
                                                <th>Status</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {list.map((item, index) => (
                                                <tr key={item.codeNumber}>
                                                    <td>{index + 1}</td>
                                                    <td>{item?.codeNumber}</td>
                                                    <td>
                                                        {moment(item?.startDate).format(
                                                            'MMM Do YY'
                                                        )}
                                                        -
                                                        {moment(item?.expiry).format(
                                                            'MMM Do YY'
                                                        )}
                                                    </td>
                                                    <td>{item?.discount}</td>
                                                    <td>{item?.description}</td>
                                                    <td>
                                                        {item?.active === true
                                                            ? 'Active'
                                                            : 'InActive'}
                                                    </td>
                                                    <td>
                                                        <Form.Check
                                                            type="switch"
                                                            id="custom-switch"
                                                            checked={item?.active}
                                                            onChange={() =>
                                                                changeCodeStatus(
                                                                    item._id,
                                                                    item?.active
                                                                )
                                                            }
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Row>
                            </Col>
                        </Row>
                    </AuthWrapper>
                </Container>
            )}
        </>
    );


}

export default Header;
