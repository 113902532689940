import React from 'react';
import Lottie from 'react-lottie';
import { commingSoonOptions } from '../../constant';
import { Row, Col } from 'react-bootstrap';

// import '../comingSoon/style.css';
import BodyWrapper from './style';

const Index = () => {
    return (
        <BodyWrapper>
             <Row className="items-center">
             <Col md={4} className='ms-lg-auto m-auto'></Col>
                        <Col lg={4}>
                            <div className='mb-2'>
                                <h2 style={{textAlign:'center',fontWeight:'bold', fontFamily:'serif'}} className='py-2'>COMMING SOON</h2>
                                <div className='pt-3'>
                                <Lottie options={commingSoonOptions} height={300} width={300} />
                                    {/* <Input placeholder="Enter Your Book's ISBN" value={isbn} onChange={(e) => setIsbn(e.target.value)} onKeyPress={moveToSellPage} /> */}
                                </div>
                                <p className='py-3' style={{textAlign:'center',fontWeight:'bold', fontFamily:'serif'}}>Something great is on the way</p>
                            </div>
                        </Col>
                        <Col md={4} className='ms-lg-auto m-auto'></Col>
                    </Row>
        </ BodyWrapper>
    )
}

export default Index;
