import { extend } from 'lodash';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import axios from '../../config/axios';

export const GetProduct = createAsyncThunk(
  '/product/get-product',
  async (value, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/products/get-product-detail?isbnOrAsin=${value}`);
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data.error,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const GetPublicProductDetail = createAsyncThunk(
  '/public/get-detail',
  async (value, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/public/get-detail?isbnOrAsin=${value}`);
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data.error,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const GetBucketItems = createAsyncThunk(
  '/bucket/get-item',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/bucket/get-item`);
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data.error,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const DeleteBucketItem = createAsyncThunk(
  '/bucket/delete-item',
  async (data, { rejectWithValue }) => {
    try {
      console.log('data ==> ', data);
      const response = await axios.delete(`/bucket/delete-item`, { data });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data.error,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const SaveOrder = createAsyncThunk(
  '/product/save-order',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/products/save-order`, data);
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data.error,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const GetOrders = createAsyncThunk(
  '/product/get-order',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/products/get-order`);
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data.error,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

const product = createSlice({
  name: 'product',
  initialState: {
   productDetail: {},
   priceDetails: {},
   transactionId: '',
   orders: [],
   bucketItems: [],
   loading: false,
   success: true,
   err: ''
  },
  reducers: {
    SetState(state, { payload: { field, value } }) {
      state[field] = value;
    },
    ClearState(state, action) {
      return {
        err: '',
        success: false,
        loading: false
      };
    },
  },
  extraReducers: {
    [GetProduct.pending]: (state, action) => ({
      ...state,
      loading: true,
      success: false
    }),
    [GetProduct.fulfilled]: (state, action) => ({
      ...state,
      productDetail: action.payload.productDetail,
      priceDetails: action.payload.priceDetails,
      loading: false,
      success: true
    }),
    [GetProduct.rejected]: (state, action) => ({
      ...state,
      loading: false,
      success: false,
      err: action.payload.err
    }),
    [GetPublicProductDetail.pending]: (state, action) => ({
      ...state,
      loading: true,
      success: false
    }),
    [GetPublicProductDetail.fulfilled]: (state, action) => ({
      ...state,
      productDetail: action.payload.productDetail,
      priceDetails: action.payload.priceDetails,
      loading: false,
      success: true
    }),
    [GetPublicProductDetail.rejected]: (state, action) => ({
      ...state,
      loading: false,
      success: false,
      err: action.payload.err
    }),
    [GetBucketItems.pending]: (state, action) => ({
      ...state,
      loading: true,
      success: false
    }),
    [GetBucketItems.fulfilled]: (state, action) => ({
      ...state,
      bucketItems: action.payload.items,
      loading: false,
      success: true
    }),
    [GetBucketItems.rejected]: (state, action) => ({
      ...state,
      loading: false,
      success: false,
      err: action.payload.err
    }),
    [SaveOrder.pending]: (state, action) => ({
      ...state,
      loading: true,
      success: false
    }),
    [SaveOrder.fulfilled]: (state, action) => ({
      ...state,
      transactionId: action.payload.transactionId,
      loading: false,
      success: true
    }),
    [SaveOrder.rejected]: (state, action) => ({
      ...state,
      loading: false,
      success: false,
      err: action.payload.err
    }),
    [GetOrders.pending]: (state, action) => ({
      ...state,
      loading: true,
      success: false
    }),
    [GetOrders.fulfilled]: (state, action) => ({
      ...state,
      orders: action.payload.orders,
      loading: false,
      success: true
    }),
    [GetOrders.rejected]: (state, action) => ({
      ...state,
      loading: false,
      success: false,
      err: action.payload.err
    }),
  }
});

const { reducer, actions } = product;

export const {
  SetState, ClearState, logout
} = actions;

export default reducer;
