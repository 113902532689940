import Styled from 'styled-components';
import Button from 'react-bootstrap/Button';


const ButtonWrapper = Styled(Button)`
      width: 100%;
      background: ${(props) => (props.variant === 'primary' ? '#c1da0b' : '')};
      border:1px solid ${(props) => (props.variant === 'primary' ? '#c1da0b' : '')};
      color:${(props) => (props.variant === 'primary' ? '#000' : '')};
      min-width: ${(props) => `${props.width || ''}`};
      min-height: ${(props) => `${props.height || ''}`};
      margin-bottom: ${(props) => `${props.bottom || ''}`};
      max-width: ${(props) => `${props.width || ''}`};
      max-height: ${(props) => `${props.height || ''}`};
      &:hover{

      }

`
export default ButtonWrapper