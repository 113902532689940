import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import AuthWrapper from './style'
import { Alert, Snackbar } from "@mui/material"
import Input from '../../component/input/index'
import Checkbox from '../../component/checkbox/index'
import Button from '../../component/button/index'
import { useDispatch, useSelector } from 'react-redux';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate, useParams } from 'react-router-dom';

import { SetState, ForgotPassword, SetPassword } from '../../redux/slice/authSlice';

const ResetPassword = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { token } = useParams();
    console.log('toke', token);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPass, setConfirmPass] = useState('');
    const [validaton, setValidation] = useState('');
    const [ info, setInfo ] = useState('')
    const { err, loading, message, resetPass } = useSelector((state) => state.auth);
    const handleReset = (e) => {
        e.preventDefault();
        const user = {
            'email': email
        }
        dispatch(ForgotPassword(user))
    }

    const handleSetPassword = (e) => {
        e.preventDefault();
        if (password === confirmPass) {
            dispatch(SetPassword({ password, token })).then( () => {
                setInfo('Password updated !');
                setTimeout(() => {
                    navigate('/login')
                }, 2000)
                
            }
            ).catch(
                console.log('error')
            )
            setValidation('');
        } else setValidation('Password and confirm password does not match');
    }

    useEffect(() => {
        setTimeout(() => {
            dispatch(SetState({ field: 'err', value: '' }));
            dispatch(SetState({ field: 'message', value: '' }));
            dispatch(SetState({ field: 'success', value : false}));
        }, 5000)
    }, [err, message]);

    useEffect(() => {
        if (resetPass) {
            dispatch(SetState({ field: 'resetPass', value: false }));
            navigate('/login');
        }
    }, resetPass)

    return (
        <>
            {err &&
                <Snackbar
                    open={err}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    autoHideDuration={6000}
                    message={err}
                />}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Container>
                <AuthWrapper>
                    <div className='text-center'>
                        <h2>{token ? 'SetPassword': 'ResetPassword'}</h2>
                    </div>
                    {!token 
                     ? <Row className='align-items-center justify-content-center mb-5' style={{marginTop: '50px'}}>
                            <Col md={4} >
                                <Input name="email" label="Eamil" value={email} onChange={(e) => setEmail(e.target.value)} />
                                {message && 
                                <p style={{ color: '#aec600' }}>{message}</p>}
                                <Button onClick={handleReset}>Reset Password </Button>
                            </Col>
                        </Row>
                      : <Row className='align-items-center justify-content-center mb-5' style={{marginTop: '50px'}}>
                            <Col md={4} >
                                <Input name="Password" label="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                <Input name="Password" label="Confirm Password" value={confirmPass} onChange={(e) => setConfirmPass(e.target.value)} />
                                {validaton && 
                                <p style={{ color: 'red' }}>{validaton}</p>}
                                {info && 
                                <p style={{ color: 'green' }}>{info}</p>}
                                <Button onClick={handleSetPassword}>Set Password </Button>
                            </Col>
                        </Row>
                    }

                </AuthWrapper>

            </Container>
        </>
    )
}

export default ResetPassword;
