import React from 'react'
import { Box, Container } from '@mui/material'
import { items, acceptItems, annotations,resources } from '../../constant/index'
import Logo from '../../static/images/logo.png'
import ItemConditionWrapper from './style'
const Index = () => {

    return (
        <ItemConditionWrapper>
            <Container>
                <Box marginBottom="16px" fontSize="24px"
                    textAlign="center"
                    fontWeight="600"
                    fontFamily=" "
                    color="#ff0000"
                >ITEMS CONDITION GUIDE</Box>
                <Box color="#093664"
                    fontWeight="600"
                    marginBottom={1}
                    fontSize="16px"
                    fontFamily=" ">
                    We cannot accept items that
                </Box>
                <Box mb={2}>
                    <ul className='list'>
                        {items.map((item) => (
                            <Box component="li" fontSize="16px" color="#093664" mb={0.5} fontFamily=" ">{item}</Box>
                        ))}
                    </ul>
                </Box>
                <Box color="#617b8d" mb={2} fontFamily=" ">
                    <Box component="span" color="#093664">Tip:</Box>
                    Give your books, CDs, games and DVDs a quick check before you send them in to avoid any disappointment.
                    Pack them well to stop any damage in transit and minimise the risk of books going missing, as we can’t pay you for missing items.
                </Box>
                <Box color="#617b8d" mb={2} fontFamily=" ">
                    We can accept items with underlining, highlighting, or annotations on the following conditions:

                </Box>
                <Box color="#093664"
                    fontWeight="600"
                    marginBottom={1}
                    fontSize="16px"
                    fontFamily=" ">
                    What we can accept
                </Box>
                <Box mb={2}>
                    <ul className='list'>
                        {acceptItems.map((item) => (
                            <Box component="li" fontSize="16px" color="#093664" mb={0.5} fontFamily=" ">{item}</Box>
                        ))}
                    </ul>
                </Box>
                <Box color="#093664"
                    fontWeight="600"
                    marginBottom={1}
                    fontSize="16px"
                    fontFamily=" ">
                    *We can accept items with underlining, highlighting, or annotations on the following conditions:
                </Box>
                <Box mb={2}>
                    <ul className='list'>
                        {annotations.map((item, key) => (
                            <Box component="li" fontSize="16px" color={item.includes('Excessive writing') ? '#617b8d' : '#093664'} mb={0.5} fontFamily=" ">{item}</Box>
                        ))}
                    </ul>
                </Box>
                <Box color="#093664"
                    fontWeight="600"
                    marginBottom={2}
                    fontSize="16px"
                    fontFamily=" ">
                    How do you check my items?
                </Box>
                <Box color="#617b8d"
                    marginBottom={2}
                    fontSize="16px"
                    fontFamily=" ">
                    Items come into our quality control team, who check each
                     item individually and compare them against the condition guidelines. If items meet those guidelines, they’re kept and added; if not, they’re rejected, and we’ll email you to let you know.
                </Box>
                <Box color="#093664"
                    fontWeight="600"
                    marginBottom={2}
                    fontSize="16px"
                    fontFamily=" ">
                    Can I challenge a rejection?
                </Box>
                <Box color="#617b8d"
                    marginBottom={2}
                    fontSize="16px"
                    fontFamily=" ">
                    Unfortunately, you can’t challenge a rejection. Our quality 
                    control team checks all items, and we can’t make payment for any
                     item not meeting the condition guidelines listed above. We’re a reseller,
                      so we have to ensure that all the books we receive are resellable; we don’t want to sell items to customers that don’t meet their expectations. If we receive a book that is damaged in a way that isn’t on this list, we can reject it.
                </Box>
                <Box color="#093664"
                    fontWeight="600"
                    marginBottom={2}
                    fontSize="16px"
                    fontFamily=" ">
                    What do you do with rejected books?
                </Box>
                <Box color="#617b8d"
                    marginBottom={2}
                    fontSize="16px"
                    fontFamily=" ">
                    If we can’t accept your books, we’ll stay true to our recycling pledge and recycle any books we can’t buy. We’ll also send any rejected books back to you if you’d prefer, but you’ll have to pay for postage on these items.

                </Box>
                <Box color="#093664"
                    fontWeight="600"
                    marginBottom={2}
                    fontSize="16px"
                    fontFamily=" ">
                    RETURN POSTAGE COSTS
                </Box>
                <Box mt={3} mb={2}>
                    <table>
                        <thead className='terms-table-header'>
                            <tr>
                                <td>
                                    <Box className="text">CD</Box>
                                </td>
                                <td>
                                    <Box className="text">DVD</Box>
                                </td>
                                <td>
                                    <Box className="text">GAME</Box>
                                </td>
                                <td>
                                    <Box className="text">BOOK*</Box>
                                </td>
                            </tr>
                        </thead>
                        <tbody className='terms-table-body'>
                            <tr>
                                <td>£1.00</td>
                                <td>£1.00</td>
                                <td>£1.00</td>
                                <td>£2.40</td>
                            </tr>

                        </tbody>
                    </table>
                </Box>
                <Box color="#617b8d"
                    marginBottom={2}
                    fontSize="16px"
                    fontFamily=" ">
                    *If the book is over 1KG, the postage costs may be more and the seller will be informed of this in advance.

                </Box>
                <Box color="#617b8d"
                    marginBottom={2}
                    fontSize="16px"
                    fontFamily=" ">
                    We’ll email you if any of your items fail the quality control process. If we don’t receive a response within three working days, we’ll call you and leave a message. If we don’t receive a response within 24 hours of the phone call, we’ll remove the cost of damaged or missing items from your Trade and recycle damaged items. You’ll still receive payment for your remaining items.

                </Box>
                <Box color="#093664"
                    fontWeight="600"
                    marginBottom={2}
                    fontSize="16px"
                    fontFamily=" ">
                  More resources
                </Box>
                <Box mb={3}>
                    <ul>
                    {resources.map((resouce)=>(
                        <Box component="li"      fontFamily=" " sx={{textDecoration:"underline"}}  color="#0563c1"> {resouce.title}</Box>
                    ))}
                    </ul>
                </Box>
                <Box textAlign="center" my={3}>
                <img width="100px" src={Logo} alt="no-logo" />
            </Box>
            </Container>
        </ItemConditionWrapper>
    )
}
export default Index;