import Styled from 'styled-components';

const HeaderWrapper = Styled.div`
.offcanvas-body{
    a{
        color:rgba(0, 0, 0, 0.55);
        font-size:16px;
        @media(max-width:991px){
            padding:14px;
            font-size:14px;
        }
    }
}
`
export default HeaderWrapper;
