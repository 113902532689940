import React from 'react'
import ButtonWrapper from './style'

const Button = (props) => {
  const { children, size, width, bottom, onClick, height
} =props
  return (
    <ButtonWrapper variant="primary" width={width} height={height} bottom={bottom} onClick={onClick}  size={size || "md"}>
        {children}
      </ButtonWrapper>
  )
}

export default Button
