import { extend } from 'lodash';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import axios from '../../config/axios';
import { setAuthToken } from '../../config/axios-configuration';

export const SignIn = createAsyncThunk(
  '/auth/sign-in',
  async (user, { rejectWithValue }) => {
    try {
      const response = await axios.post('/auth/sign-in', user);
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data.error,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const SignUp = createAsyncThunk(
  'user/SignUp',
  async (user, { rejectWithValue }) => {
    try {
      const response = await axios.post('/auth/sign-up', user);
      return response.data;

    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const GetProfile = createAsyncThunk(
  'user/get-profile',
  async (user, { rejectWithValue }) => {
    try {
      const response = await axios.get('/auth/get-profile', user);
      return response.data;

    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const GetUsers = createAsyncThunk(
  'auth/get-users',
  async (user, thunkAPI) => {
    try {
      const response = await axios.get('/auth/get-users', user);
      debugger
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return thunkAPI.rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const UpdateProfile = createAsyncThunk(
  'user/update-Profile',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post('/auth/update-Profile', data);
      return response.data;

    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
)

export const ContactUs = createAsyncThunk(
  'user/contact-us',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post('/auth/contact-us', data);
      return response.data;

    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const DisposeBooks = createAsyncThunk(
  'user/dispose-books',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post('/auth/dispose-books', data);
      return response.data;

    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const ForgotPassword = createAsyncThunk(
  'user/forgot-password',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post('/auth/forgot-password', data);
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const SetPassword = createAsyncThunk(
  'user/reset-password',
  async (data, { rejectWithValue }) => {
    try {
      const { token } = data;
      await setAuthToken(token);
      const response = await axios.post('/auth/reset-password', data);
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

const auth = createSlice({
  name: 'auth',
  initialState: {
    name: '',
    email: '',
    message: '',
    success:false,
    loading: false,
    storeFilter: '',
    err: '',
    isLogged: null,
    userType: false,
    payment: null,
    products: [],
    userList: [],
    planId: '',
    token: '',
    userId: '',
    currentUser: {},
    resetPass: false
  },
  reducers: {
    SetState(state, { payload: { field, value } }) {
      state[field] = value;
    },
    ClearState(state, action) {
      return {
        name: '',
        email: '',
        err: '',
        signup: null,
        loading: false
      };
    },
    logout: state => ({
      name: '',
      email: '',
      message: '',
      loading: false,
      success:false,
      err: '',
      signup: null,
      isLogged: null,
      token: '',
      userId: '',
      products: [],
      userList: [],
      userType: false,
      userDetails: {}
    })
  },
  extraReducers: {
    [SignIn.pending]: (state, action) => ({
      ...state,
      loading: true,
      success:false,
    }),
    [SignIn.fulfilled]: (state, action) => ({
      ...state,
      name: action.payload.user.name,
      email: action.payload.user.email,
      token: action.payload.token,
      loading: false,
      success:true,
      done: true,
      isLogged: true
    }),
    [SignIn.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.payload.err
    }),
    // Forgot password
    [ForgotPassword.pending]: (state, action) => ({
      ...state,
      loading: true,
      success:false
    }),
    [ForgotPassword.fulfilled]: (state, action) => ({
      ...state,
      message: action.payload.message,
      loading: false,
      success:true
    }),
    [ForgotPassword.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.payload.err.error
    }),
    // Set Password
    // [SetPassword.pending]: (state, action) => ({
    //   ...state,
    //   loading: true,
    //   resetPass: false,
    //   success:false
    // }),
    // [SetPassword.fulfilled]: (state, action) => ({
    //   ...state,
    //   message: action.payload.message,
    //   loading: false,
    //   resetPass: true,
    //   success:true
    // }),
    // [SetPassword.rejected]: (state, action) => ({
    //   ...state,
    //   loading: false,
    //   resetPass: false,
    //   err: action.payload.err.error
    // }),
    // sign up
    [SignUp.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [SignUp.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      signup: true
    }),
    [SignUp.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.payload.err
    }),
    [GetUsers.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [GetUsers.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      userList: action.payload.users,
      success: true
    }),
    [GetUsers.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.payload.err
    }),
    // get profile
    [GetProfile.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [GetProfile.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      userDetails: action.payload.user,
      success: true
    }),
    [GetProfile.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.payload.err
    }),
    // update profile
    [UpdateProfile.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [UpdateProfile.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      success: true
    }),
    [UpdateProfile.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.payload.err
    }),
  }
});

const { reducer, actions } = auth;

export const {
  SetState, ClearState, logout
} = actions;

export default reducer;
