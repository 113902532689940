import React, { useEffect, useState } from 'react';
import { Box, Link, Stack } from "@mui/material";
import { Table } from "react-bootstrap";
import Button from '../../../component/button/index';
import AccountWrapper from '../style';
import { useDispatch, useSelector } from 'react-redux';
import Accordion from 'react-bootstrap/Accordion';

const ProductDetail = (props, key) => {
    const { info } = props;
    return (
        <Accordion>
            <Accordion.Item eventKey={key}>
                <Accordion.Header>Info</Accordion.Header>
                <Accordion.Body>
                    <Box sx={{ border: "1px solid #dfe1e5", borderRadius: "3px" }} p={3}>
                        <Table className='data-table'>
                            <tbody>
                                {info?.map((data, key) => {
                                    return (
                                        <tr>
                                            <td style={{ width: "30px" }}><img width="50px" height="50px" src={data?.imageUrl} alt="no-book" /></td>
                                            <td style={{ width: "180px" }}><Box className="w-100" fontSize="16px" color="#093664">
                                                {data?.title}</Box>
                                                <Box component="span" color="#093664" fontSize="14px">{data?.asin}</Box></td>
                                            <td className='text-end' style={{ width: "20px" }}><Box fontWeight="600" fontSize="18px" >£{data?.amount || "N/A"}</Box></td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </Box>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}

const MyDashboard = () => {
    const [ renderOrders, setRednderOrders ] = useState([]);
    const { err, loading, orders } = useSelector((state) => state.product) || {};
    console.log('orders =>', orders);
    useEffect(() => {
        setRednderOrders(orders);
    }, [orders])
    return (
        <AccountWrapper>
            <h1 className='pb-3 pt-lg-0 pt-3'>Your Past Trades</h1>
            <Box className="dashboard-table">
                <Table>
                    <thead>
                        <tr>
                            <th>Trade ID</th>
                            <th>Trade Amount</th>
                            <th>Trade Date</th>
                            <th>Trade Status</th>
                            <th>View Details</th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderOrders?.map((item) => (
                            <tr>
                            <td>#{item.transactionId}</td>
                            <td>£{item.totalAmount}</td>
                            <td>{item.createdAt}</td>
                            <td>Pending</td>
                            <td>
                                    {/* <Button>Info</Button> */}
                                    <ProductDetail info={item.items} />
                            </td>
                        </tr>
                        ))}
                    </tbody>
                </Table>
            </Box>
        </AccountWrapper>
    )
}

export default MyDashboard
