import React from 'react';
import { Box, Stack, Grid, Modal, Typography } from '@mui/material';
import { Container, Row, Col } from 'react-bootstrap';
import Map from '../../static/images/map.png';
import Input from '../../component/input/index';
import { BsFillTelephoneFill, BsEnvelopeFill } from 'react-icons/bs';
import Button from '../../component/button/index';
import ContactWrapper from './style';

import { useDispatch, useSelector } from 'react-redux';

import { ContactUs } from '../../redux/slice/authSlice';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
};

const Index = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [formData, setFormData] = React.useState({
    name: '',
    phone: '',
    email: '',
    message: ''
  })
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const submitFeedback = () => {
    dispatch(ContactUs(formData)).then(
      handleOpen()
    ).catch(
      );
    setFormData({
      name: '',
      phone: '',
      email: '',
      message: ''
    })
  }
  return (
    <>
      <ContactWrapper>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Success
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Your query was sent successfully!
          </Typography>
        </Box>
      </Modal>
      <Box py={5}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item md={6} sm={12} xs={12}>
              <Box >
                <Box textAlign="center" fontSize="54px" color="#212529" fontWeight="bold" component="h2" >Get in touch</Box>
                <Box textAlign="center" fontSize="18px" color="#212529" mb={4}>Leave us a message and a member of our team will get back to you in 2 working days</Box>
                <div className='contact-form'>
                <Input height="54px" placeholder="Full Name" 
                  value={formData.name} 
                  onChange={(e) => setFormData({...formData, name: e.target.value })} 
                />
                <Input height="54px" type="number" placeholder="Phone Number" value={formData.phone}
                  onChange={(e) => setFormData({...formData, phone: e.target.value })} 
                >
                </Input>
                <Input height="54px" placeholder="Email  Address" 
                  value={formData.email} 
                  onChange={(e) => setFormData({...formData, email: e.target.value })} 
                />
                <Input placeholder="Message" as="textarea" rows={5}
                  value={formData.message} 
                  onChange={(e) => setFormData({...formData, message: e.target.value })} 
                />
                <Button size='lg' onClick={submitFeedback}>Send</Button>
                </div>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      
      <Box my={4} textAlign="center">
      <iframe 
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2436.926423942243!2d0.4988528!3d52.353619699999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8475a72b26ceb%3A0x18fb64cd1f729001!2sA%2C%2082%20James%20Carter%20Rd%2C%20Mildenhall%2C%20Bury%20Saint%20Edmunds%20IP28%207DE%2C%20UK!5e0!3m2!1sen!2s!4v1694550298387!5m2!1sen!2s" 
        style={{border: 0}} allowfullscreen="" className='map-img' loading="lazy" referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
            {/* <img className='map-img' src={Map} alt="no-map" /> */}
            
          </Box>
          <Container>
        <Box className="py-5">
         
          <Row mb={3}>
            <Col item lg={6}>
              <Box border="1px solid #dfdfdf" borderRadius="4px" mb={1} p={2}>
                <Stack spacing={2}>
                  <Box display="flex" alignItems="center" gap={1} fontWeight="bold" fontSize={24} >
                    <BsFillTelephoneFill />01615099417
                  </Box>
                  <Box fontSize="16px">9AM - 5PM Weekdays</Box>
                </Stack>
              </Box>
            </Col>
            <Col item lg={6}>
              <Box border="1px solid #dfdfdf" borderRadius="4px" p={2} mb={1}>
                <Stack spacing={2}>
                  <Box display="flex" alignItems="center" gap={1} fontSize={24} fontWeight="bold"  >
                    <BsEnvelopeFill />info@findmybooks.co.uk
                  </Box>
                  <Box fontSize="16px">Email us Anytime</Box>
                </Stack>
              </Box>
            </Col>
          </Row>
          <Box mt={4} textAlign="center" fontSize="24px" color="#212529" fontWeight="bold" component="h1">
            Address: Unit A, 82 James Carter Road, Mildenhall, Suffolk, IP28 7DE United Kingdom.
          </Box>
        </Box>
      </Container>
      </ContactWrapper>
    </>
  )
}
export default Index;