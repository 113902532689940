import { Nav } from 'react-bootstrap';
import Styled, { styled } from 'styled-components';

const HeaderWrapper = Styled.div`
.offcanvas-body{
    a{
        color:rgba(0, 0, 0, 0.55);
        font-size:16px;
    }
}`

const FooterWrapper = Styled.div`
    background-color:#5e5f61;
    padding:24px 0;`
const ListWrapper = Styled.div`
    h5{
        color:#9d694b
    }
   ul{
 li{
          color:${({ theme }) => theme.colors.listColor};
        padding-bottom:8px;
        cursor:pointer;
        &:hover{
                 color:${({ theme }) => theme.colors.onPrimary};
        }
    }
   }
`
const CopyRight = Styled.div`
background-color:#9e9e9e;
padding:40px;
text-align:center;
`

const AuthWrapper = Styled.div`
    h2{
        font-size:56px;
    }
    .form-control{
      min-height: 48px;
    }
  .trash-button{
    background: none;
    border: none;
    fill: red;
  }

`
 const StyledNavLink = styled(Nav.Link)`
  color: #333;
  margin-right: 15px;
  
  &.active {
    color: #007bff;
    font-weight: bold;
    border-bottom: 2px solid #c1da0b;
  }
  
  &:hover {
    color: #0056b3;
  }
`;
export { FooterWrapper, ListWrapper, CopyRight, HeaderWrapper, AuthWrapper, StyledNavLink }
