import { extend } from 'lodash';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import axios from '../../config/axios';

export const CreateCode = createAsyncThunk(
  '/promo-code/create',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post('/promo-code/create', data);
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data.error,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const GetCodesList = createAsyncThunk(
  '/promo-code/get',
  async (user, { rejectWithValue }) => {
    try {
      console.log('user parameter', user);
      const response = await axios.get(`/promo-code/get?check=${user ? 'admin' : ''}`);
      return response.data;

    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const UpdateCode = createAsyncThunk(
  '/promo-code/update',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put('/promo-code/update', data);
      return response.data;

    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const VerifyCode = createAsyncThunk(
  '/promo-code/verify',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post('/promo-code/verify', data);
      return response.data;

    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

const promoCode = createSlice({
  name: 'promoCode',
  initialState: {
    message: '',
    success:false,
    loading: false,
    err: '',
    list: []
  },
  reducers: {
    SetState(state, { payload: { field, value } }) {
      state[field] = value;
    },
    ClearState(state, action) {
      return {
        name: '',
        email: '',
        err: '',
        signup: null,
        loading: false
      };
    }
  },
  extraReducers: {
    [CreateCode.pending]: (state, action) => ({
      ...state,
      loading: true,
      success:false
    }),
    [CreateCode.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      success:true
    }),
    [CreateCode.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.payload.err
    }),
    // verify code
    [VerifyCode.pending]: (state, action) => ({
      ...state,
      loading: true,
      success:false
    }),
    [VerifyCode.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      check: action.payload.check,
      success:true
    }),
    [VerifyCode.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.payload.err
    }),
    // Get Code List
    [GetCodesList.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [GetCodesList.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      list: action.payload.getList,
      success: true
    }),
    [GetCodesList.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.payload.err
    })
  }
});

const { reducer, actions } = promoCode;

export const {
  SetState, 
  ClearState
} = actions;

export default reducer;
