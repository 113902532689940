import React, { useEffect, useState } from 'react'
import { extend } from 'lodash';
import { Container, Row, Col } from 'react-bootstrap'
import { Alert, Box } from "@mui/material"
import AuthWrapper from './style'
import { Client } from 'getaddress-api';
import Input from '../../component/input/index'
import Checkbox from '../../component/checkbox/index'
import Button from '../../component/button/index'
import Select from '../../component/select/index'
import { SignUp } from '../../redux/slice/authSlice'
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom'
import Backdrop from '@mui/material/Backdrop';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { useDispatch, useSelector } from 'react-redux';

const api = new Client("5x7az0e8-U6iI-v6ZCN3_w36106");


const Register = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // My account states
    const [scInput1, setSCInput1] = useState();
    const [scInput2, setSCInput2] = useState();
    const [scInput3, setSCInput3] = useState();
    const [accountNumber, setAccountNumber] = useState();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [agreeTC, setAgreeTC] = useState(false);
    // end here
    const [value, setValue] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [addressArr, setAddressArr] = useState([]);
    const [confrimPassword, setConfrimPassword] = useState('')
    const { err, loading, signup } = useSelector((state) => state.auth);
    const [errorMessage, setErrorMessage] = useState('')
    const [user, setUser] = useState({
        firstName: "",
        lastName: "",
        phoneNumber: "",
        country: "",
        address: "",
        addressLine2: "",
        town: "",
        postalCode: "",
        paymentMethod: 'bank',
        paymentdetails: {},
        email: "",
        password: ""
    });

    const option = [{
        label: "Bank",
        value: 'bank',
    },
    {
        label: "Cheque",
        value: 'cheque',
    },
    {
        label: "Paypal",
        value: 'paypal',
    }]

    useEffect(() => {
        if (user.password !== confrimPassword) {
            setErrorMessage("Password Must be Same")
        }
        else {
            setErrorMessage('')
        }
    }, [confrimPassword])

    const handleRegister = (event) => {
        if (!agreeTC) return;
        event.preventDefault();
        if (!errorMessage) {
            if (user.paymentMethod === "bank") {
                extend(user, { paymentdetails:{ scInput1, scInput2, scInput3, accountNumber } })
            } else if (user.paymentMethod === "cheque") {
                extend(user, { paymentdetails: { name } })
            } else extend(user, { paymentdetails:{  email } })
            dispatch(SignUp(user))
        }
    }

    useEffect(() => {
        if (signup) {
            navigate("/login")
        }
    }, [signup]);

    const getAddress = (postCode) => {
        setPostalCode(postCode);
        setUser({ ...user, postalCode: postCode })
        api.find(postCode)
            .then(value => {
                console.log(value.addresses.addresses)
                const tempArr = [];
                for (const item of value.addresses.addresses) {
                    tempArr.push({
                        label: item.line_1,
                        year: item.line_1
                    })
                }
                setAddressArr(tempArr);
            })
            .catch(e => console.log('error', e));
    }

    return (
        <>
            {err && <Alert severity="error">{err.error}</Alert>}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Container>
                <AuthWrapper>
                    <div className='text-center'>
                        <h2>Register</h2>
                    </div>
                    <Row className='justify-content-center'>
                        <Col lg={8} md={10}>
                            <Row className='align-items-center justify-content-center mb-5'>
                                <Col md={6} sm={12} xs={12}>
                                    <Input name="firstName" label="First name" value={user.firstName} onChange={(e) => setUser({ ...user, firstName: e.target.value })} />
                                </Col>
                                <Col md={6} sm={12} xs={12}>
                                    <Input label="Last name" name="lastName" value={user.lastName} onChange={(e) => setUser({ ...user, lastName: e.target.value })} />
                                </Col>
                                <Col md={6} sm={12} xs={12}>
                                    <Input label="Phone number" name={"phoneNo"} value={user.phoneNumber} onChange={(e) => setUser({ ...user, phoneNumber: e.target.value })} />
                                </Col>
                                <Col md={6} sm={12} xs={12}>
                                    <Input label="Email" name={"email"} value={user.email} onChange={(e) => setUser({ ...user, email: e.target.value })} />
                                </Col>
                                <Col md={6} sm={12} xs={12}>
                                    <Input value={postalCode} label="Find by Postcode" onChange={(e) => getAddress(e.target.value)} placeholder="Postal Code" />
                                </Col>
                                <Col md={6}>
                                    <Box mb={1}>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            options={addressArr}
                                            onChange={(e, value) => setUser({ ...user, address: e.target.value })}
                                            // onInputChange={(e) => setAddress(e)}
                                            size='small'
                                            renderInput={(params) => <TextField {...params} label="Select Address" />}
                                        />
                                    </Box>
                                </Col>
                                {/* <Col md={6}>
                                    <Button width="100px" bottom="16px">Search </Button>
                                </Col> */}
                                {/* <Col md={6}>
                                    <Input label="Line 1" name={"addressLine1"} value={user.addressLine1} onChange={(e) => setUser({ ...user, addressLine1: e.target.value })} />
                                </Col>
                                <Col md={6}>
                                    <Input label="Line 2" name={"addressLine2"} value={user.addressLine2} onChange={(e) => setUser({ ...user, addressLine2: e.target.value })} />
                                </Col> */}
                                <Col md={6} sm={12} xs={12}>
                                    <Input label="Line 2" name={"addressLine3"} value={user.addressLine2} onChange={(e) => setUser({ ...user, addressLine2: e.target.value })} />
                                </Col>
                                <Col md={6} sm={12} xs={12}>
                                    <Input label="Town" name={"town"} value={user.town} onChange={(e) => setUser({ ...user, town: e.target.value })} />
                                </Col>
                                <Col md={6} sm={12} xs={12}>
                                    <Input label="County" name={"country"} value={user.country} onChange={(e) => setUser({ ...user, country: e.target.value })} />
                                </Col>
                                <Col md={6} sm={12} xs={12}>
                                    <Input label="Post Code" name={"postalCode"} value={user.postalCode} onChange={(e) => setUser({ ...user, postalCode: e.target.value })} />
                                </Col>
                                {/* <Col md={12} sm={12} xs={12}>
                                    <Select onChange={(e) => setUser({ ...user, paymentMethod: e.target.value })} label="Payment Method" option={option} />
                                </Col> */}
                                {/* <Col md={6} sm={12} xs={12}>
                                    {value === '' ? <Input label="Payment Info" /> : value === 'bank' ?
                                        <Input label="Bank Account Sort Code" /> : value === "paypal"
                                            ? <Input label="Email Needed to receive your PayPal payment" />
                                            : <Input label="The name of the person the cheque should be made payable to" />}
                                </Col> */}
                                
                                    {/* <Col md={12} sm={12} xs={12}>
                                            {user.paymentMethod === "bank" ?
                                                <>
                                                    <Row>
                                                        <Col md={6}>
                                                            <Box component="label" mb={1}>Sort Code</Box>
                                                            <Row>
                                                                <Col md={3}>
                                                                    <Input
                                                                        placeholder="00"
                                                                        value={scInput1}
                                                                        onChange={(e) => { if (e.target.value.length < 3) setSCInput1(e.target.value) }}
                                                                    />
                                                                </ Col>
                                                                <Col md={3}>
                                                                    <Input
                                                                        placeholder="00"
                                                                        value={scInput2}
                                                                        onChange={(e) => { if (e.target.value.length < 3) setSCInput2(e.target.value) }}
                                                                    />
                                                                </ Col>
                                                                <Col md={3}>
                                                                    <Input
                                                                        placeholder="00"
                                                                        value={scInput3}
                                                                        onChange={(e) => { if (e.target.value.length < 3) setSCInput3(e.target.value) }}
                                                                    />
                                                                </ Col>
                                                            </ Row>
                                                        </Col>
                                                        <Col md={6}>
                                                            <Input
                                                                label="Account Number"
                                                                value={accountNumber}
                                                                placeholder="12345678"
                                                                onChange={(e) => { if (e.target.value.length < 9) setAccountNumber(e.target.value) }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </>
                                                :
                                                user.paymentMethod === "cheque"
                                                    ? <Input label="The name of the person the cheque should be made payable to" value={name} onChange={(e) => setName(e.target.value)} placeholder="Name" />
                                                    : <Input label="Email Needed to receive your PayPal payment" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" />}
                                    </Col> */}

                                {/* <Col md={6} sm={12} xs={12}>
                                    {value === 'bank' ? <Input label="Bank Account Number" /> : ""}
                                </Col> */}
                                <Col md={6} sm={12} xs={12}>
                                    <Input label="Password" name={"password"} value={user.password} onChange={(e) => setUser({ ...user, password: e.target.value })} />
                                </Col>
                                <Col md={6} sm={12} xs={12}>
                                    <Input label="Confirm password" statusType={errorMessage ? 'error' : ''} name="confrimPassword" inputStatus={errorMessage} onChange={(e) => setConfrimPassword(e.target.value)} />
                                </Col>
                                <Col md={12} sm={12} xs={12}>
                                    <Checkbox label="Yes, I want to receive emails" />
                                    <Checkbox checked={agreeTC} onChange={() => setAgreeTC(!agreeTC)} label="Yes, I agree to the Terms and Conditions" />
                                </Col>
                                <Col md={12} sm={12} xs={12}>
                                    <Button width="100%" bottom="16px" onClick={handleRegister}>Register </Button>
                                </Col>

                            </Row>
                        </Col>
                    </Row>

                </AuthWrapper>

            </Container>
        </>
    )
}

export default Register
