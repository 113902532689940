import React from 'react'
import { Box, Container } from '@mui/material'
import { packageGuideline } from '../../constant/index'
import Logo from '../../static/images/logo.png'
const Index = () => {

    return (
        <Container>
            <Box marginBottom="16px" fontSize="24px"
                textAlign="center"
                fontWeight="600"
                fontFamily=""
                color="#ff0000"
            >PACKAGING GUIDELINES </Box>
            {packageGuideline.map((packageguide) => {
                return (
                    <>
                        <Box color="#093664"
                            fontWeight="600"
                            marginBottom={2}
                            fontSize="16px"
                            fontFamily="">
                            {packageguide.title}
                        </Box>
                        <Box color="#617b8d"
                            marginBottom={2}
                            fontSize="16px"
                            fontFamily="">
                            {packageguide.pargraph}
                        </Box>
                    </>
                )
            })}
            <Box textAlign="center" my={3}>
                <img width="100px" src={Logo} alt="no-logo" />
            </Box>
        </Container>
    )
}
export default Index;