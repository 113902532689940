import { createGlobalStyle } from 'styled-components';


const GlobalStyles = createGlobalStyle`

    body{
        font-size:${({ theme }) => theme.colors.baseFontSize};
        font-weight:400;
        line-height:normal;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; 
    }
    h1,h2,h3,h4,h5,h6{
         font-weight:700;
         color:${({ theme }) => theme.colors.checkBoxColor}
    }
    a{
        color:${({ theme }) => theme.colors.primaryColor};
        text-decoration:none;
    }
    ul{
        margin:0;
        padding:0;
        list-style:none;
      }
    .title{
        font-size:${({ theme }) => theme.colors.baseFontSizeXl};
        line-height:44px;
        margin-bottom:32px;
    }
    h1{
        font-size:${({ theme }) => theme.colors.baseFontSizeXl };
    }
    h2{
        font-size:${({ theme }) => theme.colors.baseFontSizeLg};
    }
    h3{
        font-size:${({ theme }) => theme.colors.baseFontSizeSm}
    }
    h5{
        font-size:${({ theme }) => theme.colors.baseFontSizeLg}
    }
    img{
        max-width: 100%;
    }
    /* @media (min-width: 768px){
.container, .container-md, .container-sm {
    max-width: 900px;
}
}
@media (max-width: 576px){
.container, .container-sm {
    max-width: 670px;
}
} */
.width-medium{
    width: 25%;
    @media(max-width:768px){
        width:100%;
    }
}
`;
export default GlobalStyles;
