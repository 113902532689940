import ComingSoonData from '../static/images/commingsoon.json';
const items=['Have significant damage or wear to the binding',
    'Have a book missing from the box set',
    'Have damage to the popup',
    'Are incomplete',
    'Are a misprint',
    'Look like they’ve been chewed on','Are corrected or uncorrected proof copies of a book','Are promo copies','Are print-on-demand, or self-published books',
    'Are missing dust jackets','Are missing any accompanying CDs or DVDs','Have animal hair, excrement or insects in them',
    'Are stained by food or drink or contain crumbs','Have missing or wrong discs','Have tears to the cover or pages','Are fire or smoke damaged','Have missing, loose or torn pages','Have a strong smell of any kind (including cigarette odour)','Have water, mould or moisture damage','Are ex-rental copies'
,'Are workbooks with writing in them (e.g. tests part or fully completed)','Have an ISBN or barcode number different than that on the packaging even if the title - is the same','Have an access code that’s missing or used','Are badly stained','Are copied or pirated'
,'Contain discs with damaged or missing original packaging, artwork or instructions','Contain discs with cracks, dents, deep scratches or that are misshapen','Have faulty or failed sound','Have cracks in the case']
const acceptItems=['Ex-library books that are in reasonable condition','Folio books with or without their slip-case','Books that don’t have an ISBN; visit the Find My Books catalogue or contact us for a price','DVDs, CDs and games with light surface scratches, providing they don’t impact the disc’s playability','DVDs, CDs, and game cases that have light surface scratches but are complete and unbroken','Books with a small amount of writing, underlining or highlighting of pages, as long as it’s on fewer than 20% of the book’s pages*']
const annotations=['There are no crude words or pictures','There are no pictures coloured in','It doesn’t cover more than 50% of any given page','It isn’t on more than 20% of the book’s pages','It doesn’t affect the text','Excessive writing, underlining, and highlighting or ‘remainder’ marks on page edges may also reduce the quoted price or the book, or we may reject it.']
const resources=[
{title:'Packaging guidelines', to:"#"},
{title:'FAQs',to: '/' },
{title:'Visit your selling basket',to: '/' }
]
const packageGuideline=[
    {title:'Use correct number of boxes', pargraph:"Pack your items into the correct number of boxes as determined by our clever box calculator. When creating multiple trades please do not mix trades in boxes and ensure the label affixed corresponds to the contents. This means we can easily track your items - ensuring you get paid faster."},
    {title:'Each box needs a separate label, extra labels can be found in your account',pargraph: '' },
    {title:'Box Sizes',pargraph: 'Your box should weigh no more than 15kg and be no more than 120cm in length. If you add the two shortest dimensions and multiply them by two, the total calculation should be under 225cm.' },
    {title:'High quality packaging',pargraph: 'Whatever you decide to use to package your books – make sure it’s strong and durable. Corrugated cardboard is a great choice as they’re cushioned, and heavy duty.' },
    {title:'Secure your items',pargraph: 'Bubble Wrap is the ideal material to secure your items in the box and prevent damage. Try to pack heavier items first and dont use a box thats too large - your items will move around and could damage one another. Use strapping where possible to seal and secure your box.' },
    {title:'Double check your items',pargraph: 'It might sound obvious but make sure you are sending all the items that are listed in your selling basket.' },
    {title:'Remove any existing labels',pargraph: 'Remove any labels, hazardous materials indicators, and other previous shipment labels. Ensure your label is attached securely and the barcode is not obscured by tape. This will allow your box to be easily tracked - meaning you get paid quicker!' },
    {title:'Post within 7 days',pargraph: 'Prices offered for books, CDs and DVDs remain in place for 7 days, so to make sure you get the cash you’re expecting please post your items within this time frame.' },
    {title:'Badly packaged',pargraph: 'Don’t use flimsy, or damaged boxes as they may receive further damage whilst in transit. Ensure the box is in good shape and not worn out.' },
    {title:'Label as fragile instead of careful packing.',pargraph: 'Labelling your boxes as ‘fragile’ or ‘handle with care’ aren’t a substitute for careful packing.' },
    {title:'Don’t allow contents to move.',pargraph: 'Choose the appropriate size of box for your items, try not to under-fill boxes (these could collapse) and over-filled ones might explode.' },
    {title:'Resend packaged without removing old labels',pargraph: 'Don’t re-use old packaging without removing all previous labels and stickers.' },
    ]
     const commingSoonOptions = {
        loop: true,
        autoplay: true,
        animationData: ComingSoonData,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      };
export {items,acceptItems,annotations,resources,packageGuideline,commingSoonOptions}