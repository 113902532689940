import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import AuthWrapper from './style';
import { Alert, Snackbar } from "@mui/material";
import Input from '../../component/input/index';
import Checkbox from '../../component/checkbox/index';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Button from '../../component/button/index';
import { SignIn } from '../../redux/slice/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';

import { SetState } from '../../redux/slice/authSlice';

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [viewPass, setViewPass] = useState(false);
    const [checked, setChecked] = useState(false);
    const { err, loading, success, signup } = useSelector((state) => state.auth);
    const handleGetValue = (event) => {
        if (event?.charCode === 13) {
            const user = {
                'email': email,
                'password': password,
                'remember': checked
            }
            dispatch(SignIn(user))
        }
    }
    const handleLogin = (e) => {
        e.preventDefault();
        const user = {
            'email': email,
            'password': password,
            'remember': checked
        }
        dispatch(SignIn(user))
    }

    // useEffect(() => {
    //     dispatch(SetState({ field: 'err', value: '' }));
    // }, []);

    useEffect(() => {
        if (success) {
            if (email === 'admin@findmybooks.com') {
                navigate("/admin");
            }
            navigate("/");
        }
    }, [success]);

    useEffect(() => {
        dispatch(SetState({ field: 'signup', value: null }));
    }, [signup])

    return (
        <>
            {err &&
                <Snackbar
                    open={err}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    autoHideDuration={6000}
                    message={err}
                />}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Container>
                <AuthWrapper>
                    <div className='text-center'>
                        <h2>Login</h2>
                    </div>
                    <Row className='align-items-center justify-content-center mb-5'>
                        <Col md={4} >
                            <Input name="email" label="Eamil" value={email} onChange={(e) => setEmail(e.target.value)} />
                            <Input name="password" onKeyPress={handleGetValue} type={!viewPass ? "password" : 'text'} label="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                            {/* <a href="#" onClick={() => setViewPass(!viewPass)}>show</a> */}
                            <RemoveRedEyeIcon onClick={() => setViewPass(!viewPass)} style={{ float: "right", marginTop: "-50px", marginRight: "10px"}}></RemoveRedEyeIcon>
                            <br></br>
                            <a style={{cursor: 'pointer'}} onClick={() => navigate('/reset-password')}>Forgot your password?</a>
                            <p style={{marginBottom: '10px'}}></p>
                            <Button onClick={handleLogin}>Login </Button>
                            <p style={{textAlign: 'center', marginTop: '20px'}}>OR</p>
                            <p style={{textAlign: 'center', marginTop: '20px', color: '#aec600', fontSize: '18px', cursor: 'pointer'}} onClick={() => navigate('/register')}>Sign Up</p>
                        </Col>
                    </Row>

                </AuthWrapper>

            </Container>
        </>
    )
}

export default Login
