import React, { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

// components
import Home from "../container/home/index";
import Sell from "../container/sell/index";
import Admin from "../component/admin";
import Layout from "../layout/index";
import Login from "../container/auth/login";
import Register from "../container/auth/register";
import ResetPassword from "../container/auth/reset-password";
import SellItems from "../container/sellitems/index";
import MyAccount from "../container/myAccount/index";
import TermsConditions from "../container/termsConditions/index";
import ItemGuideLine from "../container/itemCondition/index";
import PackageGuideline from "../container/packageGuideline/index";
import PrivacyPolicy from "../container/privacyPolicy/index";
import ContactUs from "../container/contactUs";
import ComingSoon from "../container/comingSoon";
import DisposeBooks from "../container/disposeBooks";
import { useSelector } from "react-redux";
import { setAuthToken } from "../config/axios-configuration";

const AppRoutes = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { token, email } = useSelector((state) => state.auth);
  const privateRoutes = ["/my-account", "/sell-items/"];
  setAuthToken(token);
  if (token) {
    console.log('==>', location.pathname)
    if (location.pathname === '/login') {
      navigate('/');
    }
  } else {
    if (privateRoutes.includes(location.pathname)) {
      alert('redirect to login');
      navigate("/login");
    }
  }

  return (
    <>
      {" "}
      {email === "admin@findmybooks.com" ? (
        <Routes>
          <Route exact path="*" element={<Admin />} />
          <Route exact path="/admin" element={<Admin />} />
        </Routes>
      ) : (
        <Layout>
          {token && token !== "" && (
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/sell/:id" element={<Sell />} />
              <Route exact path="/my-account" element={<MyAccount />} />
              <Route exact path="/sell-items/:id" element={<SellItems />} />
              <Route
                exact
                path="/terms-conditions"
                element={<TermsConditions />}
              />
              <Route
                exact
                path="/items-guideline"
                element={<ItemGuideLine />}
              />
              <Route
                exact
                path="/packaging-guide"
                element={<PackageGuideline />}
              />
               <Route
                exact
                path="/coming-soon"
                element={<ComingSoon />}
              />
               <Route
              exact
              path="/dispose-books"
              element={<DisposeBooks />}
            />
              <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route exact path="/contact-us" element={<ContactUs />} />
              <Route exact path="/sell" element={<Sell />} />
            </Routes>
          )}
          {!token && (
            <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/sell/:id" element={<Sell />} />
            <Route exact path="/sell" element={<Sell />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/register" element={<Register />} />
            <Route
              exact
              path="/reset-password/:token"
              element={<ResetPassword />}
            />
            <Route exact path="/reset-password" element={<ResetPassword />} />
            <Route
              exact
              path="/terms-conditions"
              element={<TermsConditions />}
            />
            <Route
              exact
              path="/coming-soon"
              element={<ComingSoon />}
            />
             <Route
              exact
              path="/dispose-books"
              element={<DisposeBooks />}
            />
            <Route exact path="/items-guideline" element={<ItemGuideLine />} />
            <Route
              exact
              path="/packaging-guide"
              element={<PackageGuideline />}
            />
            <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route exact path="/contact-us" element={<ContactUs />} />
          </Routes>
          ) }
        </Layout>
      )}
    </>
  );
};

export default AppRoutes;
