import * as React from 'react';
import { Accordion, Box } from '@mui/material';
import { Container, Row, Col, Table } from 'react-bootstrap'
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionWrapper from "./style"

export default function SimpleAccordion(props) {
    const { heading, ProductsDetail } =props
    return (
        <AccordionWrapper>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Box component="span">{heading}</Box>
                </AccordionSummary>
                <AccordionDetails>
                            <Box sx={{ border: "1px solid #dfe1e5", borderRadius: "3px" }} p={3}>
                                <Table className='data-table'>
                                    <tbody>
                                        {ProductsDetail?.map((data, key) => {
                                            return (
                                                <tr>
                                                    <td style={{ width: "50px" }}><img width="50px" height="50px" src={data?.imageUrl} alt="no-book" /></td>
                                                    <td><Box className="w-75" fontSize="16px" color="#093664">
                                                        {data?.title}</Box>
                                                        <Box component="span" color="#093664" fontSize="14px">{data?.asin}</Box></td>
                                                    <td className='text-end'><Box fontWeight="600" fontSize="18px" >£{data?.amount || "N/A"}</Box></td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </Box>
                </AccordionDetails>
            </Accordion>
        </AccordionWrapper>
    );
}