import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { Container, Row ,Col} from "react-bootstrap";
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AccountWrapper from './style';
import {
    Route,
    Routes
} from 'react-router-dom';
import MyDashboard from './component/myDashboard';
import PastOffer from './component/pastOffers';
import MyDetails from './component/myDetails';

import { GetProfile } from '../../redux/slice/authSlice';
import { GetOrders } from '../../redux/slice/getProductSlice';

function handleClick(event) {
    event.preventDefault();
}

export default function Index() {
    const dispatch = useDispatch();
    const [step, setStep] = useState(2);

    const onStepChange = (value) => {
        setStep(value);
    }

    useEffect(() => {
        dispatch(GetOrders());
        dispatch(GetProfile());
    }, []);

    return (
    <Container>
            <AccountWrapper>
        <div className="my-5" onClick={handleClick}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/">
                    Find my books
                </Link>
                <Link
                    underline="hover"
                    color="inherit"
                    href="/material-ui/getting-started/installation/"
                >
                    My Account
                </Link>
                {/* <Typography color="text.primary">Breadcrumbs</Typography> */}
            </Breadcrumbs>
            <Row className="my-5">
                <Col lg={3} md={8}>
                    <ul className='user-list'>
                                {/* <li className={step === 1 ? 'active' : ''} onClick={() => onStepChange(1)}> <DashboardOutlinedIcon />Dashboard</li> */}
                                <li className={step === 2 ? 'active' : ''} onClick={() => onStepChange(2)}><LocalOfferOutlinedIcon />Past Trades</li>
                                <li className={step === 3 ? 'active' : ''} onClick={() => onStepChange(3)}><InfoOutlinedIcon />My Details</li>
                    </ul>
                    </Col>
                    <Col lg={9}>
                            <Routes>
                                 <Route exact path="/my-account/dashboard" element={<MyDashboard />} />
                            </Routes>
                            {step === 2 
                              ? <PastOffer /> 
                              : step === 3
                                ? <MyDetails />
                                : ''}
                        </Col>


            </Row>
        </div>
            </AccountWrapper>
        </Container>
    );
}