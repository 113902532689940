import React from 'react'
import Nav from 'react-bootstrap/Nav';
import { Container, Row, Col } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { FooterWrapper, ListWrapper, CopyRight } from './style.js'

const Footer = () => {
    const items = [
        {title: "My Selling Basket",link: "/selling-basket"},
        {title: "Recycle My Books",link: "/dispose-books"},
        {title: "Our Mobile App",link: "/coming-soon"},
        {title: "Item Condition Guidelines",link: "/items-guideline"},
        {title: "Packaging Guidelines",link: "/packaging-guide"}]
        const links = [
            {title: 'How To Buy', link: 'https://www.amazon.co.uk/s?me=A1EI66NOP4ELEY&marketplaceID=A1F83G8C2ARO7P'},
            {title: 'FAQs', link: ''},
            {title: 'How It works', link: ''},
            {title: 'What Is An ISBN', link: ''},
        ]
        const account = [
            {title: 'Log In', link: '/login'},
            {title: 'Reviews', link: 'https://uk.trustpilot.com/review/findmybooks.co.uk'},
            {title: 'Register', link: '/register'},
            {title: 'Forgot Password', link: '/reset-password'},
        ]
        const comapny = [
        {title: "About Us",link: "/about-us" }, 
        {title: "Contact Us",link: "/contact-us"},
        {title: "Terms and Conditions",link: "/terms-conditions"}, 
        {title: "Privacy Policy",link: "/privacy-policy"},
        {title: "Blog",link: "/blog"}]
    const navigate = useNavigate();

    return (
        <>
            <FooterWrapper>
                <Container>
                    <Row>
                        <Col md={3} className='mb-4'>
                            <ListWrapper>
                                <h5 className='mb-3'>Sell My Items</h5>
                                <ul>
                                    {items.map((item,index) => <li key={index} onClick={()=>navigate(item.link)}>{item.title}</li>)}
                                </ul>
                            </ListWrapper>
                        </Col>
                        <Col md={3} className='mb-4'>
                            <ListWrapper>
                                <h5 className='mb-3'>Important Links</h5>
                                <ul>
                                    {links.map((link, index) => <li key={index} onClick={()=>window.open(link.link)}>{link.title}</li>)}
                                </ul>
                            </ListWrapper>
                        </Col>
                        <Col md={3} className='mb-4'>
                            <ListWrapper>
                                <h5 className='mb-3'>My Account</h5>
                                <ul>
                                    {account.map((item) => <li>
                                        <Nav.Link onClick={()=>window.open(item.link)}>{item.title}</Nav.Link>
                                    </li>)}
                                </ul>
                            </ListWrapper>
                        </Col>
                        <Col md={3} className='mb-4'>
                            <ListWrapper>
                                <h5 className='mb-3'>Company</h5>
                                <ul>
                                    {comapny.map((comapny) => <li onClick={()=>navigate(comapny.link)}>{comapny.title}</li>)}
                                </ul>
                            </ListWrapper>
                        </Col>
                    </Row>
                </Container>

            </FooterWrapper>
            <CopyRight>
           Copyright © All Right Reserved {new Date().getFullYear()}.
            </CopyRight>
        </>
    )
}

export default Footer
