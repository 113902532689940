import { extend } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Box, Stack, Alert } from '@mui/material';
import { Row, Col, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Client from 'getaddress-api';
import Button from '../../../component/button/index';
import Input from '../../../component/input/index';
import DatePicker from "../../../component/datePicker/index";
import Radio from '../../../component/Radio/index';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { GetProfile, UpdateProfile } from '../../../redux/slice/authSlice';

const api = new Client("5x7az0e8-U6iI-v6ZCN3_w36106");

const MyDetails = () => {
  const dispatch = useDispatch();
  const [scInput1, setSCInput1] = useState();
  const [scInput2, setSCInput2] = useState();
  const [scInput3, setSCInput3] = useState();
  const [accountNumber, setAccountNumber] = useState();
  const { err, loading, userDetails } = useSelector((state) => state.auth) || {};
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
    const [paypalEmail, setPaypalEmail] = useState('');
  // const [paymentOption, setPaymentOption] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [postalCode, setPostalCode] = useState();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [selectedValue, setSelectedValue] = useState('bank');
  const [addressArr, setAddressArr] = useState([]);
  const [address, setAddress] = useState();
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const getAddress = (postCode) => {
    setPostalCode(postCode);
    api.find(postCode)
      .then(value => {
        console.log(value.addresses.addresses)
        const tempArr = [];
        for (const item of value.addresses.addresses) {
          tempArr.push({
            label: item.line_1,
            year: item.line_1
          })
        }
        setAddressArr(tempArr);
      })
      .catch(e => console.log('error', e));
  }

  const updateProfile = () => {
    const userDetails = {
      firstName,
      lastName,
      phoneNumber,
      dateOfBirth: startDate,
      address: address?.label,
      paymentMethod: selectedValue,
      postalCode
    }
    if (selectedValue === "bank") {
      extend(userDetails, { paymentdetails:{ scInput1, scInput2, scInput3, accountNumber } })
  } else if (selectedValue === "cheque") {
      extend(userDetails, { paymentdetails: { name } })
  } else extend(userDetails, { paymentdetails:{  email: paypalEmail } })
    dispatch(UpdateProfile({ userDetails }));
  }

  useEffect(() => {
    if (userDetails) {
      setFirstName(userDetails?.firstName);
      setLastName(userDetails?.lastName);
      setEmail(userDetails?.email);
      if (userDetails?.dateOfBirth) {
        setStartDate(new Date(userDetails?.dateOfBirth));
      }
      const paymentdetails = userDetails?.paymentdetails;
      setSelectedValue(userDetails?.paymentMethod);
      if (userDetails?.paymentMethod === 'bank') {
        
        setSCInput1(paymentdetails?.scInput1);
        setSCInput2(paymentdetails?.scInput2);
        setSCInput3(paymentdetails?.scInput3);
        setAccountNumber(paymentdetails?.accountNumber);
      } else if (userDetails?.paymentMethod === 'cheque') {
        setName(paymentdetails?.name);
      } else {
        setPaypalEmail(paymentdetails?.email);
      }
      setPhoneNumber(userDetails?.phoneNumber);
      setAddress(userDetails?.address);
      setPostalCode(userDetails?.postalCode);
    }
    console.log(address);
  }, userDetails)

  return (

    <div className='pt-4'>
      <h1>Your Information</h1>
      <Box component="span" color="#617b8d">View or update your contact information here.</Box>
      <h2 className='my-4'>My Details</h2>
      <Row className='pb-3'>
        <Col md={12}>
          {/* <Input label="Title*" width="150px" placeholder="MS" /> */}
        </Col>
        <Col md={6}>
          <Input label="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} placeholder="" /></Col>
        <Col md={6}>
          <Input label="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} placeholder="" /></Col>
        <Col md={6}>
          <Input label="Email" value={email} disabled={true} placeholder="Email" /></Col>
        <Col md={12}>
          <Input label="Telephone" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} placeholder="Telephone" /></Col>
        <Col md={12}>

          <DatePicker selected={startDate} label="Date of Birth" onChange={(date) => setStartDate(date)} /></Col>
      </Row>
      <h2 className='my-3'>Address Details</h2>
      <Box component="span" color="#617b8d">Simply enter
        your postcode and choose
        the right address from the list shown.
      </Box>
      <label className='d-block pb-2 pt-4'>Find by Postcode</label>
      <Row className='pb-4'>
        <Col md={6}> <Input width="100%" height="56px" value={postalCode} onChange={(e) => getAddress(e.target.value)} placeholder="Postal Code" /></Col>
        <Col md={6} > <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={addressArr}
          onChange={(e, value) => setAddress(value)}
          renderInput={(params) => <TextField {...params} label="Select Address" />}
        />
          {/* <Button >Search</Button> */}</Col>
      </Row>
      <h2>Payment Details</h2>
      <Box component="span" color="#617b8d">
        How would you like to be paid? (We'll pay you as soon as we've received your items and checked their condition. We offer three payment methods)
      </Box>
      <Form className='mt-4 mb-4'>
        <Row>
          <Col md={6}>

            <div className="mb-3 payment-method" onClick={() => setSelectedValue('bank')}>
              <Radio checked={selectedValue === 'bank'}
                value="bank" label="💳 Bank Transfar" />
            </div>

          </Col>
          <Col md={6}>
            <div className="mb-3 payment-method" onClick={() => setSelectedValue('paypal')}>
              <Radio checked={selectedValue === 'paypal'}
                value="paypal" label="PayPal" />
            </div>

          </Col>
          <Col md={6}>
            <div className="mb-3 payment-method" onClick={() => setSelectedValue('cheque')}>
              <Radio checked={selectedValue === 'cheque'} value="cheque" onChange={handleChange} label="✍🏻 cheque" />
            </div>

          </Col>
        </Row>
      </Form>
      <div>
        {selectedValue === "bank" ?
          <>
            <h2 className='mb-4'>Bank Account Sort Code & Account Number</h2>
            <Row>
              <Col md={6}>
                  <Box component="label" mb={1}>Sort Code</Box>
                  <Row>
                      <Col md={3}>
                          <Input
                              placeholder="00"
                              value={scInput1}
                              onChange={(e) => { if (e.target.value.length < 3) setSCInput1(e.target.value) }}
                          />
                      </ Col>
                      <Col md={3}>
                          <Input
                              placeholder="00"
                              value={scInput2}
                              onChange={(e) => { if (e.target.value.length < 3) setSCInput2(e.target.value) }}
                          />
                      </ Col>
                      <Col md={3}>
                          <Input
                              placeholder="00"
                              value={scInput3}
                              onChange={(e) => { if (e.target.value.length < 3) setSCInput3(e.target.value) }}
                          />
                      </ Col>
                  </ Row>
              </Col>
              <Col md={6}>
                  <Input
                      label="Account Number"
                      value={accountNumber}
                      placeholder="12345678"
                      onChange={(e) => { if (e.target.value.length < 9) setAccountNumber(e.target.value) }}
                  />
              </Col>
          </Row>
            {/* <Row>
              <Col md={6}>
                <Input label="Sort Code" />
              </Col>
              <Col md={6}>
                <Input label="Account Number" />
              </Col>
            </Row> */}
          </>
          : selectedValue === "cheque"
            ? <Input value={name} onChange={(e) => setName(e.target.value)} label="The name of the person the cheque should be made payable to" /> 
            : <Input value={paypalEmail}  onChange={(e) => setPaypalEmail(e.target.value)} label="Email Needed to receive your PayPal payment" />}
      </div>
      <Alert severity="success" color="info" icon={false} sx={{ justifyContent: "center", color: "#1889fa" }}>
        {selectedValue === "bank" ? <>Payment will be made directly into your account the <b>next working day</b> after your items have been checked</> : selectedValue === "paypal" ?
          <>Direct transfer to your Paypal account within the <b>next working day</b> after your items have been checked</> :
          <>We'll post you cheque the <b>next working day</b> after your items have been checked</>}
      </Alert>
      <Box my={4}>
        <Button size="lg" onClick={() => updateProfile()}>Confirm Details & Continue</Button>
        {/* <>
                {err &&
                    <Snackbar
                        open={err}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        autoHideDuration={6000}
                        message={err}
                    />}
                </> */}
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>


    </div>
  )
}

export default MyDetails
