import Form from 'react-bootstrap/Form';
import InputWrapper from './style'

function FormTextExample(props) {
    const { 
        label, 
        type, 
        placeholder, 
        width, 
        value, 
        onChange, 
        name, 
        disabled,
        inputStatus,
        statusType,
        onKeyPress,
        rows,
        height,
        as
    } = props
    return (
        <InputWrapper width={width} height={height} className='pb-3'>
            {label && <Form.Label>{label}</Form.Label>}
            <Form.Control
                type={type}
                placeholder={placeholder}
                value={value}
                name={name}
                disabled={disabled}
                onChange={onChange}
                onKeyPress={onKeyPress}
                as={as}
                rows={rows}
            />
            {inputStatus && <Form.Text className={`status ${statusType}`}>
               {inputStatus}
            </Form.Text>}
        </InputWrapper>
    );
}

export default FormTextExample;