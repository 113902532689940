import * as React from 'react';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

export default function RadioButtonsGroup(props) {
    const { value, label, checked, onChange
} =props;
    return (
        <FormControl className='w-100'>
            <FormControlLabel value={value} control={<Radio
                checked={checked}
                onChange={onChange} sx={{
                '& .MuiSvgIcon-root': {
                    fontSize: 16,
                },
            }} />} label={label} />
        </FormControl>
    );
}