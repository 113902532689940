import Styled from 'styled-components';
const SellWrapper = Styled.div`
.sell-box{
    border: 1px solid #ccc;
    border-radius:4px;
    img{
        width:100%;
        height: 181px;

        object-fit: contain;
    }
    h3{
        font-size:25px;
    }
    @media(max-width:767px){
        height:auto;
        img{
          height: 300px;
        }
    }
}
.data-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;

  tr {
    border-bottom: 1px solid #ccc;
    display: flex;
    align-items: center;
    .img-data{
      width: 50px;
      padding: 10px 0;
    }
  }

  td {
    padding: 10px;
    border: none;
  }

  img {
    width: 50px;
    height: 50px;
    display: block;
    margin: 0 auto;
  }

  .description {
    font-size: 16px;
    color: #093664;
  }

  .asin {
    font-size: 14px;
    color: #093664;
  }

  .amount {
    font-weight: 600;
    font-size: 18px;
  }

  .remove-icon {
    cursor: pointer;
  }
}

.find-my-book{
      background-color:#eef7a6;
      padding:80px 40px;
      .heading-find-books{
          font-size:32px;
      }
}
.total-amount{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width:200px;
}
.description{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.MuiSnackbarContent-root{
  background-color: white !important;
  color: red !important;
}
`
export default SellWrapper