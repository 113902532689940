import React, { useEffect, useState } from 'react';
import { isEmpty, round } from "lodash";
import { Snackbar } from "@mui/material";
import { json, useParams, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Table } from 'react-bootstrap';
import { Box, Paper, Grid } from '@mui/material';
import Input from '../../component/input/index';
import Button from '../../component/button/index';
import SellOffice from '../../static/images/sellOffice.jpg';
import Cash from '../../static/images/cash.png';
import Shipping from '../../static/images/free-shipping.jpg';
import PaymentInfo from '../../static/images/payment-info.jpg';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import SellWrapper from "./style";
import { notification } from 'antd';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import { useDispatch, useSelector } from 'react-redux';
import { GetProduct, GetBucketItems, DeleteBucketItem, GetPublicProductDetail } from '../../redux/slice/getProductSlice';
import { SetState } from '../../redux/slice/authSlice';
import { SetState as ProductSliceState } from '../../redux/slice/getProductSlice';

const Index = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { id } = useParams();
  const [searchValue, setSearchValue] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [validation, setValidation] = useState(false);
  const { token, ProductsDetail } = useSelector((state) => state.auth);
  const { err, loading, productDetail, bucketItems, searchValue: isbn, success } = useSelector((state) => state.product);
  const [product, setProduct] = useState([]);
  const seller = [{
    img: SellOffice,
    heading: "Enquire",
    caption: "Do you have questions or need to know more about our services? No problem, simply fill the enquiry form and we will respond to your message as soon as possible."
  },
  {
    img: Cash,
    heading: "Exchange",
    caption: "Turn in all your books/ CDs / DVDs/ games for instant cash. It’s that simple! Find out how much your items are worth by entering their ISBNs or barcodes in our home page, and you will get instant valuation."
  },
  {
    img: Shipping,
    heading: "Shipping",
    caption: "You can use our free courier collection service or free drop off service to deliver your books/ CDs / DVDs/ games to us."
  },
  {
    img: PaymentInfo,
    heading: "Payment",
    caption: "Get paid via bank transfer, Paypal, or by cheque."
  }]

  const handleGetValue = (event) => {
    if (event?.keyCode !== 0 && searchValue && searchValue.length === 10 || searchValue.length === 13) {
      const validateTwiceEntry = product.filter(item => item.asin === searchValue);

      if (validateTwiceEntry.length === 2) {
        dispatch(ProductSliceState({ field: 'err', value: 'Oops! You cannot add more than two copies' }));
      } else {
        if (token) {
          dispatch(GetProduct(searchValue));
        } else dispatch(GetPublicProductDetail(searchValue));
      }
    }
  }

  const persistAmount = () => {
    if (totalAmount < 5) {
      setValidation(true);
    } else {
      console.log('token => ', token);
      dispatch(SetState({ field: 'ProductsDetail', value: product }));
      if (!token) {
        navigate('/login');
      } else {
        setValidation(false);
        dispatch(SetState({ field: 'totalAmount', value: totalAmount }));
        navigate('/sell-items/:id');
      }
    }
  }

  const removeProduct = (index) => {
    const item = product.splice(index, 1)[0];
    console.log('deleted item is ==>', item);
    setTotalAmount(totalAmount - item.amount);
    if (!item?._id) {
      dispatch(SetState({ field: 'ProductsDetail', value: JSON.parse(JSON.stringify(product)) }));
    } else {
      dispatch(DeleteBucketItem({ _id: item._id }));
    }
    console.log('products are => ', product);
    setProduct(product);
  }

  useEffect(() => {
    if (!isEmpty(productDetail)) {
      setProduct([...product, { ...productDetail }]);
    } if (ProductsDetail?.length) {
      console.log('product details are ==> ', ProductsDetail.length);
      setProduct(ProductsDetail);
    }
  }, [productDetail, ProductsDetail]);

  useEffect(() => {
    let amount = 0;
    if (product?.length) {
      product?.map((item) => {
        amount = amount + item.amount;
      })
      amount = round(amount, 2)
    }
    setTotalAmount(amount);
    setSearchValue('');
  }, [product]);

  useEffect(() => {
    if (bucketItems.length) {
      setProduct([...bucketItems])
    }
  }, [bucketItems])

  useEffect(() => {
    if (err) {
      setTimeout(() => { dispatch(ProductSliceState({ field: 'err', value: '' })) }, 20000);
    }
  }, [err])

  useEffect(() => {
    dispatch(GetBucketItems());
  }, [])

  useEffect(() => {
    if(isbn) {
      setTimeout(()=> {
        if (token) dispatch(GetProduct(isbn));
        else dispatch(GetPublicProductDetail(isbn));
        dispatch(ProductSliceState({ field: 'searchValue', value: '' }));
      }, 1000);
    }
  }, [isbn])

  return (
    <SellWrapper>
      {err &&
        <Snackbar
          open={err}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={6000}
          message={err}
        />}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container className='my-5'>
        <div className='text-center px-5'>
          <h1 className='mb-5'>Sell</h1></div>
        <Row className='justify-content-center'>
          <Col lg={4} md={4} className="pb-3">
            <Input
              name="searchValue"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              onKeyPress={handleGetValue}
              placeholder="Enter Your Book's ISBN"
            />
          </Col>
          <Col lg={3} md={5}>
            <Button size="lg" onClick={handleGetValue}>Get value</Button>
          </Col>

        </Row>
        {product?.length ?
          <Box>
            <Box textAlign="center">
              <h1 className='py-4'>My Items Valuation</h1>
            </Box>
            <Row >
              <Col lg={8} md={8}>
                <Box sx={{ border: "1px solid #dfe1e5", borderRadius: "3px" }} mb={1} p={3}>
                  <Table className='data-table'>
                    <tbody>
                      {product?.map((data, index) => (
                        <tr key={index}>
                          <td className='img-data'>
                            <img className="product-image" src={data?.imageUrl} alt="no-book" />
                          </td>
                          <td>
                            <div className='inner-data ps-sm-2'>
                              <Box className="description" fontSize="16px">
                                {data?.title}
                              </Box>
                              <Box component="span" className="asin" fontSize="14px">
                                {data?.asin}
                              </Box>
                            </div>
                          </td>
                          <td className='text-end ms-auto'>
                            <Box className="amount">
                              £{data?.amount || "N/A"}
                            </Box>
                          </td>
                          <td className='close-btn'>
                            <HighlightOffIcon
                              className="remove-icon"
                              onClick={() => removeProduct(index)}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Box>
              </Col>
              <Col lg={4} md={4}>
                <Paper elevation={1} sx={{ padding: "16px", textAlign: "center" }}>
                  <h1>Total Value</h1>
                  {validation &&
                    <p style={{ color: "#1889fa" }}>Kindly add more items to your cart to get to our minimum trade amount of £5.00</p>
                  }
                  <Box component="h1" className='total-amount mx-auto' sx={{ textOverflow: 'ellipsis' }} color="#1889fa">£{totalAmount || "N/A"}</Box>
                  <Button onClick={() => persistAmount()} >Next</Button>
                </Paper>
              </Col>
            </Row>
          </Box> : ""}
        <Row className='mt-5'>
          {seller.map((sell) => {
            return (
              <Col lg={3} md={6}>
                <Box className='sell-box mb-5' style={{height: '400px'}}>
                  <img src={sell.img} alt="no-data" />
                  <div className='p-4 text-md-start text-center'>
                    <h3>{sell.heading}</h3>
                    <p>{sell.caption}</p>
                  </div>
                </Box>
              </Col>
            )
          })}

        </Row>
      </Container>
      <div className='find-my-book  text-center'>
        <Container>
          <h2 className='heading-find-books'>Find My Books</h2>
          <p>©2023 - Find My Books Ltd
Unit A, 82 James Carter Road, Mildenhall, Suffolk, IP28 7DE United Kingdom.

          </p>
        </Container>
      </div>
    </SellWrapper>
  )
}

export default Index
