import React, { useState } from 'react';
import { Row, Col, Form, Table } from "react-bootstrap"
import { Box, Alert, Paper, Link } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import PaymentSell from './style'
import Radio from '../Radio/index'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Input from '../input/index'
import Button from '../button/index'
import Accordion from '../accordion/index'


const steps = ['', '', ''];

export default function HorizontalLinearStepper(props) {
    const {children} = props
    const [activeStep, setActiveStep] = useState(1);
    const [skipped, setSkipped] = React.useState(new Set());

    const isStepOptional = (step) => {
        return step === 1;
    };
    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const [selectedValue, setSelectedValue] = useState('bank');
    const [selectedPayment, setSelectedPayment] = useState('drop-off');
    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };
    return (
        <Box sx={{ width: '100%' }}>
            <Stepper className="width-medium" sx={{ margin: "0 auto", marginBottom: "40px" }} activeStep={activeStep}>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (isStepOptional(index)) {
                        labelProps.optional = (
                            <Typography variant="caption"></Typography>
                        );
                    }
                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
                {children}
        </Box>
    );

}