import styled from 'styled-components';

const SliderWrapper = styled.div`
  .banner-slider {
    width: 100%;
    .slide{
      position: relative;
      display: flex;
      align-items: center;
      color: white;
      padding: 50px 0;
      .overlay{
        position: absolute;
        width: 100%;
        height: 100%;
        display: block;
        top: 0;
        right: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.4);
        z-index: -1;
      }
      img{
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        z-index: -2;
        top: 0;
        right: 0;
        left: 0;
      }
      .slide-text{
        color: #fff;
        h1{
          color: inherit;
          font-size: 56px;
        }
        p{
          padding: 16px 0;
          line-height: 24px;
          font-weight: 500;
          max-width: 600px;
          font-size: 18px;
        }
        .btn{
          width: auto;
          padding-left: 60px;
          padding-right: 60px;
        }
      }
    }
  }
`;

export default SliderWrapper;
