import Styled from 'styled-components';
const AccountWrapper = Styled.div`
.user-list{
    padding: 0;
    margin: 0;
    li{
        border-radius: 5px;
    padding: 10px 15px;
    width: 100%;
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #093664;
    font-size: 1.1em;
    font-weight: 600;
    letter-spacing: -.5px;
    text-decoration: none;
    margin-bottom: 2px;
    cursor: pointer;
    svg{
    width: 1.25em;
    display: inline-block;
    text-align: center;
    color: #1889fa;
    font-size: 1.2em;
        margin-right: 8px;
}
&:hover{
    background: #093664;
    color: #fff;
    font-weight: 700;
    svg{
        color:#fff
    }
}
&.active{
     background: #093664;
    color: #fff;
    font-weight: 700;
    svg{
        color:#fff
    }  
}
}

    }

.dashboard-table{

overflow-x: scroll;
    table{
 thead{
        tr{
            background-color:#c1da0b;
            th{
padding:14px;
    color: #fff;
    min-width: 136px;
            }
            
        }
    }
    }
    tbody{
        tr{
            background-color: rgba(0,0,0,.05);
            td{
                background: #effaff;
    color: #093664;
    padding:14px;
            }
        }
    }
   
}
   .payment-method{
       border:1px solid #ccc;
       border-radius:4px;
       padding:16px;
       font-size:16px;
       color:#000;
   }
   #combo-box-demo{
    margin-bottom: 0;
   }
`
export default AccountWrapper