import React from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DatePickerWrapper from './style'
const Index = (props) => {
    const { selected, onChange, label}=props
  return (
      <>
      <DatePickerWrapper>
        <label>{label}</label>

          <DatePicker selected={selected} onChange={onChange} />
          </DatePickerWrapper>
      </>

  )
}

export default Index;
