import Styled from 'styled-components';

const HomeWrapper = Styled.div`
  background-color:#eef7a6;
  padding:76px 0;
  img{
      max-width:100%
  }
  h1{
    font-size:56px;
      color:#000 ;
  }
  p{
    color:#000 ;
    font-size:20px;
    margin-bottom:0;
  }
  .items-center{
      align-items: center
  }

`
const ImagesSection = Styled.div`
padding:76px 0;
text-align:center;
h2{
    font-size:56px;
}
.images-box{
    border:1px solid #ccc;
    border-radius:4px;
    height:100%;
    @media(max-width:992px){
        height:100%;
        min-height:312px;
    }
    p{
        padding: 28px 20px 0;
    }
    img{
      height: 272px;
      object-fit: contain;
    }
}
`
const VideoSection = Styled.div`
padding: 76px 0;
  background-color:#eef7a6;

.home-list{
  li{
    font-size: 24px;
  }
}
h2{
    font-size:56px;
    color:#212529;
    font-weight:700;
}
.video-section{
    max-width:100%
}
svg{
    color:#0d6efd;
    margin-right:4px;
}
`
const CommentSection = Styled.div`
padding: 76px 0;
h1{
    font-size:40px;
}
.comment-box{
      border:1px solid #ccc;;
      padding:24px;
      border-radius:4px;
      display:flex ;
      gap:24px;
      svg{
          font-size:40px;
          color:#0d6efd
      } 
      caption{
          width:100%;
          display:flex ;
      } 
}
`
export { HomeWrapper, ImagesSection, VideoSection, CommentSection }