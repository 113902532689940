import React from 'react';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
// components
import AppRoute from './routes/AppRoutes';
// styles
import GlobalStyles from './config/theme/global.styled';
import theme from './config/theme/themeVariables';

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <AppRoute  />
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
