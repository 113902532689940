import React from 'react'
import { Container } from 'react-bootstrap'
import { Box, Link, Grid, Stack, ListItemText } from "@mui/material"
import ListWrapper from './style'
const Index = () => {

    return (
        <ListWrapper>
            <Container>
                <Box textAlign="center" px={4} my={7}>
                    <Box component="h1" mb={4} fontSize="60px">PRIVACY POLICY</Box>
                    <Box compoent="h2" fontSize="24px" fontWeight="600">Please Read Properly As We Will Not Be Held Liable For Any Breach.</Box>
                </Box>
            </Container>
            <Box bgcolor="#eef7a6">
                <Container>
                    <Box py={6} >
                        <Box textAlign="center">
                            <Box compoent="h2" mt={2} fontSize="24px" fontWeight="700">Find my books Privacy Policy</Box>
                        </Box>
                        <Box mt={2}>
                            <Box fontSize="20px" fontWeight="600" color="#111111">Our contact details </Box>
                            <Stack mt={1}>
                                <Box component="span" mb={1.5} fontSize="15px">Name: Find my books Ltd </Box>
                                <Box component="span" mb={1.5} fontSize="15px">Phone Number: 01615099417 </Box>
                                <Box component="span" mb={1.5} fontSize="15px">E-mail: <Link underline="none">info@findmybooks.co.uk</Link></Box>
                            </Stack>
                        </Box>
                        <Box fontSize="16px" color="#111111" my={2}>
                            Find my books privacy policy sets out how we  use and protect any information provided by our website users and visitors while using the services provided by us on findmybooks.co.uk.
                        </Box>
                        <Box fontSize="16px" color="#111111" my={2}>
                            Find my books is committed to ensuring that your privacy is protected. All information provided by you in the course of using our website is assured to be used  in accordance with this privacy statement and UK GDPR.
                        </Box>
                        <Box fontSize="16px" color="#111111" my={2}>
                            This privacy policy also explains your rights in relation to your personal data and how to contact us in case of any personal data related issue.
                        </Box>
                        <Box fontSize="16px" color="#111111" my={2}>
                            Revisiting this policy often is advised as progressive changes will be made if the need arises and if the law so demands.
                        </Box>
                        <Box fontSize="20px" fontWeight="600" color="#111111" mb={2}>The Type of Personal Information We Collect. </Box>
                        <Box fontSize="16px" color="#111111" my={2}>
                            We currently collect and process the following information:
                        </Box>
                        <ul className='list-style-disc'>
                            <li >
                                Personal identifiers, contacts and other characteristics (for instance, name, email, phone number)
                            </li>
                            <li >
                                Demographic information such as address and postcode.
                            </li>
                            <li >
                                Payment details.
                            </li>
                            <li >
                                Other information relevant to customer surveys and/or offers/trades.
                            </li>
                        </ul>
                        <Box fontSize="20px" fontWeight="600" color="#111111" mb={2}>Device and Connection Information </Box>
                        <ul className='list-style-disc'>
                            <li >
                                We may collect information about your computer, including where available,  your IP address, operating system and browser type for system administration and to report anonymous, statistical aggregate information to our advertisers. This are statistical data about our users’ browsing actions and patterns and does not identify any individual.
                            </li>
                            <li >
                                Your IP address will be logged in our weblogs for up to 7 days. Our weblogs are for analytical and debugging purposes only and are not used for processing or profiling.
                            </li>
                            <li >
                                Your IP address is not disclosed to third parties.
                            </li>
                        </ul>
                        <Box fontSize="20px" fontWeight="600" color="#111111" mb={2}>How we get the personal information and what we do with the information we gather?</Box>
                        <Box fontSize="16px" color="#111111" my={2}>
                            All the personal information we process is provided to us directly by you and we require them for the following reasons:
                        </Box>
                        <ul className='list-style-disc'>
                            <li >
                                Internal record keeping.
                            </li>
                            <li >
                                We may use the information to improve our products and services.
                            </li>
                            <li >
                                We may also collect information to assist customer service enquiries.
                            </li>
                            <li >
                                We may use it to send email communication from Find my books specifically and only relating to your use, or potential use of this service.
                            </li>
                            <li >
                                From time to time, we may also use your information to contact you for market research purposes.
                            </li>
                            <li >
                                We may use your personal data to send you updates (by email, text message, telephone or post) about our products and services, including exclusive offers, promotions or new products and services.
                            </li>
                            <li >
                                Allow you to participate in live chat, Twitter, Facebook, Pinterest, RSS Feeds, feedback, reviews and any other interactive features on our website.
                            </li>
                            <li >
                                • No personally identifiable information is transferred or stored outside of the European Economic Area (‘EEA’), such as name, address, phone number and any payment information.
                            </li>
                        </ul>
                        <Box fontSize="20px" fontWeight="600" color="#111111" mb={2}>Where do we store your information? </Box>
                        <Box fontSize="16px" color="#111111" my={2}>
                            Personal information held by Find my books is stored on and processed on computers situated in the United Kingdom.
                        </Box>
                        <Box fontSize="16px" color="#111111" my={2}>
                            Personal data may also be held by agencies, service providers like Evri (our courier partner), representatives and agent. Some of these third parties may be based outside the UK.
                        </Box>
                        <Box fontSize="20px" fontWeight="600" color="#111111" mb={2}>How long do we store your information? </Box>
                        <Box fontSize="16px" color="#111111" my={2}>
                            We will store your information for up to 6 years after you have last used our services. After such time, we will permanently delete your details.
                        </Box>
                        <Box fontSize="20px" fontWeight="600" color="#111111" mb={2}>Security.</Box>
                        <Box fontSize="16px" color="#111111" my={2}>
                            We are committed to ensuring that your information is secure. In order to prevent unauthorised access or disclosure, we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect online.
                        </Box>
                        <Box fontSize="20px" fontWeight="600" color="#111111" mb={2}>Your rights as a data subject.</Box>
                        <Box fontSize="16px" color="#111111" my={2}>
                            At any point whilst Find My Books Ltd is in possession of or processing your personal data, all data subjects have the following rights:
                        </Box>
                        <ul className='list-style-disc'>
                            <li >
                                Right of access – you have the right to request a copy of the information that we hold about you.
                            </li>
                            <li >
                                Right of rectification – you have a right to correct data that we hold about you that is

                                inaccurate or incomplete.
                            </li>
                            <li>Right to be forgotten – in certain circumstances you can ask for the data we hold about you to be erased from our records.</li>
                            <li>Right to restriction of processing – where certain conditions apply you have a right to restrict the processing.</li>
                            <li>Right of portability – you have the right to have the data we hold about you transferred to another organisation.</li>
                            <li>Right of portability – you have the right to have the data we hold about you transferred to another organisation.</li>
                            <li>Right to object to automated processing, including profiling – you also have the right not to be subject to the legal effects of automated processing or profiling</li>
                        </ul>
                        <Box fontSize="20px" fontWeight="600" color="#111111" mb={2}>Controlling your personal information</Box>
                        <Box fontSize="16px" color="#111111" my={2}>
                            You may choose to restrict the collection or use of your personal information in the following ways:
                        </Box>
                        <ul className='list-style-disc'>
                            <li >
                                you may stop all communications from us at any time by contacting us.
                            </li>
                            <li >
                                in the event that you have chosen to subscribe to our newsletter, then you may unsubscribe at any time by simply clicking the “unsubscribe” link at the bottom of each email.
                            </li>
                        </ul>
                        <Box fontSize="16px" color="#111111" my={2}>
                            We will not sell, distribute or lease your personal information to third parties unless we have your permission or are required by law to do so.
                        </Box>
                        <Box fontSize="20px" fontWeight="600" color="#111111" mb={2}>Cookies</Box>
                        <ul className='list-style-disc'>
                            <li >
                                The website uses a cookie to identify any list maintained while the user is not logged in.
                            </li>
                            <li >
                                Find My Books also uses what is commonly called ‘remarketing’ or ‘retargeting’ to show adverts on other websites specifically to previous visitors to the Find My Books website.
                            </li>
                            <li>Third party vendors, including Google, show our adverts on sites across the internet,

                                specifically in the UK.</li>
                            <li>Third party vendors, including Google, use cookies to serve ads based on past visitors to the Find My Books website.</li>
                            <li>You can opt out of Google’s use of cookies by visiting their Ad Settings.

                            </li>
                            <li>You may also opt out of Google, and any or all third-party vendor’s use of cookies by visiting the Network Advertising Initiative opt-out page</li>
                        </ul>
                        <Box>Under the UK General Data Protection Regulation (UK GDPR), the lawful bases we rely on for processing this information is your consent. You are able to remove <b> your consent at any time. You can do this by contacting <Link>info@findmybooks.co.uk</Link></b></Box>
                    </Box>

                    <Box>

                    </Box>
                </Container>
            </Box>
        </ListWrapper>

    )
}
export default Index
