import Styled from 'styled-components';
const PaymentSell = Styled.div`
margin-Bottom:24px;
   .payment-method{
       border:1px solid #ccc;
       border-radius:4px;
       padding:16px;
       font-size:16px;
       color:#000;
   }
    .disabled{
           filter: grayscale(1);
       }
   .select-items{
       border:2px solid #007eff;
       border-radius:4px;
 
   }
   .recommended{
       background-color: #3489fb;
    border-top-left-radius: 12.5px;
    border-top-right-radius: 12.5px;
    display: inline-block;
    padding: 4px 10px;
    color: #fff;
    font-weight: 700;

   }
   .method{
    position: absolute;
    top: 0;
    transform: translateY(-50%);
    left: 20px;
    background-color: #1789fb;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    padding: 4px 8px;
    border-radius: 12.5px;
   }
   .data-table{
       border-color:transparent;ß
   }
   .width-medium{
    width:25%;
   }
`
export default PaymentSell;