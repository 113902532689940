import React from 'react'
import { Box, Link, Stack } from "@mui/material"
import {Table} from "react-bootstrap"
import Button from '../../../component/button/index'
import AccountWrapper from '../style'

const MyDashboard = () => {
  return (
      <AccountWrapper>
          <h1>My Account Dashboard</h1>
          <Box component="p" sx={{ color:"#093664",fontSize:"20px"}} mt={2}>From Your Dashboard you have the ability to update your account information,
           manage the items you are sending and view your past offers.</Box>
          <Box component="h2" mb={4} color="093664">Packages You're Sending</Box>
          <Box className="dashboard-table">
              <Table>
                  <thead>
                      <tr>
                          <th>Offer ID</th>
                          <th>Date</th>
                          <th>Amount</th>
                          <th>Method</th>
                          <th></th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr>
                          <td>#2040017</td>
                          <td>2023-05-20 13:09:27</td>
                          <td>£12.95</td>
                          <td><Box>
                              <Box>Dropoff</Box>
                                 <Link>Change</Link>
                              </Box></td>
                              <td>
                                <Stack spacing={2} >
                                  <Button>Download And Print</Button>
                                  <Button>Re-Send Email</Button>
                              </Stack>
                                  </td>
                      </tr>
                  </tbody>
              </Table>
          </Box>
      </AccountWrapper>
  )
}

export default MyDashboard
