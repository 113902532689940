import Styled from 'styled-components';
const ListWrapper = Styled.div`
font-size:18px;
ul{
    padding-left:24px;
}
p{
    padding-left:24px;   
}
.list-style-disc{
    list-style:disc;
    padding-left:24px;
}
.list-style-circle{
    list-style:circle; 
    padding-left:16px;
}
.list-style-square{
    list-style:square;  
    padding-left:24px;   
}
li{
    font-size:18px;
    color:#212529;
    line-height:24px;
    margin-bottom:24px;
}
table{
    width:100%;
}
a{
    color:#0000FF;
    cursor:pointer;
}
`
export default ListWrapper;