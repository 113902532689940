import React from 'react';
import Form from 'react-bootstrap/Form';
function SelectBasicExample(props) {

    const { option, label, onChange}=props
    return (
        <div className='mb-4'>
        { label && <Form.Label>{label}</Form.Label>}
            <Form.Select onChange={onChange}>
            {label && <Form.Label>{label}</Form.Label>}
            {option.map((option) => <option value={option.value}>{option.label}</option>)}
        </Form.Select>
        </div>
    );
}

export default SelectBasicExample;