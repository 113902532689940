import React, { useState, useEffect } from 'react';
import { sample } from 'lodash';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SliderWrapper from './style';
import { Container } from 'react-bootstrap';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Tooltip from '@mui/material/Tooltip';
import Button from '../../component/button/index';

const BannerSlider = ({ listPromo }) => {
  console.log('list promo', listPromo);
  const [list, setList] = useState([]);
  const imagesList = [
    'https://images.unsplash.com/photo-1485322551133-3a4c27a9d925?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3270&q=80',
    'https://images.unsplash.com/photo-1485322551133-3a4c27a9d925?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3270&q=80',
    'https://images.unsplash.com/photo-1521587760476-6c12a4b040da?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3270&q=80',
    'https://images.unsplash.com/photo-1524995997946-a1c2e315a42f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3270&q=80'
  ]

  const copyIcon = 'https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.pngegg.com%2Fen%2Fsearch%3Fq%3Dcopy%2BIcon&psig=AOvVaw1mAGAFzmHN59-M6GxEUI0Z&ust=1701154070540000&source=images&cd=vfe&opi=89978449&ved=0CBIQjRxqFwoTCLD_tfDK44IDFQAAAAAdAAAAABAJ';

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  }; 

  const copyCode = (codeNumber) => {
    console.log('code is ==> ', codeNumber);
    navigator.clipboard.writeText(codeNumber);
  }

  useEffect(() => {
    setList(listPromo);
  }, [listPromo]);

  return (

    <SliderWrapper>
      <div className="banner-slider">
        <Slider {...settings}>
          {list.map((item, index) => {
            return (
              <div className="slide">
                <div className='overlay'></div>
                <img src={sample(imagesList)} />
                <Container>
                  <div className='slide-text'>
                    <h1 onClick={() => copyCode(item?.codeNumber)}>
                      {item?.codeNumber}
                      <Tooltip title="Copy" placement="top">
                        <ContentCopyIcon />
                      </Tooltip>
                    </h1>
                    <p>{item?.description}</p>
                    <Button size="lg">
                      Get up to {item?.discount}%
                    </Button>
                  </div>
                </Container>
              </div>
            )
          })}
          {/* <div className="slide">
            <div className='overlay'></div>
            <img src="" />

            <Container>
              <div className='slide-text'>
                <h1>Slide 1</h1>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release</p>
                <Button size="lg">
                  View
                </Button>
              </div>
            </Container>
          </div>
          <div className="slide">
            <div className='overlay'></div>
            <img src="" />

            <Container>
              <div className='slide-text'>
                <h1>Slide 2</h1>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release</p>
                <Button size="lg">
                  View
                </Button>
              </div>
            </Container>
          </div>
          <div className="slide">
            <div className='overlay'></div>
            <img src="" />

            <Container>
              <div className='slide-text'>
                <h1>Slide 3</h1>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release</p>
                <Button size="lg">
                  View
                </Button>
              </div>
            </Container>
          </div> */}
          
        </Slider>
      </div>
    </SliderWrapper>
  );
};

export default BannerSlider;
