import React from 'react';
// components
import Header from '../component/header/index';
import Footer from '../component/footer/index';

const Index = ({ children }) => (
    <>
        <Header />
        <div>{children}</div>
        <Footer />
    </>
);

export default Index;
