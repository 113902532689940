import React, { useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import MultiSelectWrapper from "./style";
import "bootstrap/dist/css/bootstrap.min.css";

const MultiSelectInput = ({ userList, setUser, disabled }) => {
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [showOptions, setShowOptions] = useState(false);

  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };

  const handleOptionClick = (e, optionValue) => {
    if (!disabled) {
      if (selectedOptions.includes(optionValue)) {
        setSelectedOptions(
          selectedOptions.filter((value) => value !== optionValue)
        );
      } else {
        setSelectedOptions([...selectedOptions, optionValue]);
      }
    }
  };

  useEffect(() => {
    const temp = [];
    for (let i = 0; i < userList.length; i += 1) {
      const { name, _id } = userList[i];
      temp.push({
        label: name,
        value: _id,
      });
    }
    setOptions(temp);
  }, [userList]);

  useEffect(() => {
    setUser(selectedOptions);
  }, [selectedOptions]);

  return (
    <MultiSelectWrapper>
      <Form className="pb-3">
        <InputGroup>
          <div
            className="form-control multi-select-input"
            onClick={toggleOptions}
          >
            {selectedOptions.length === 0
              ? "Select options"
              : selectedOptions
                  .map(
                    (optionValue) =>
                      options.find((opt) => opt.value === optionValue)?.label
                  )
                  .join(", ")}
          </div>
          {showOptions && (
            <ul className="multi-select-options">
              {options.map((option) => {
                return (
                  <li key={option.value}>
                    <label>
                      <input
                        type="checkbox"
                        checked={selectedOptions.includes(option.value)}
                        onChange={(e) => handleOptionClick(e, option.value)}
                        disabled={disabled}
                      />
                      <span
                        className={
                          selectedOptions.includes(option.value)
                            ? "selected"
                            : ""
                        }
                      >
                        {option.label}
                      </span>
                    </label>
                  </li>
                );
              })}
            </ul>
          )}
        </InputGroup>
      </Form>
    </MultiSelectWrapper>
  );
};

export default MultiSelectInput;
