import Styled from 'styled-components';

const InputWrapper = Styled.div`
    input{
            width:${(props) => (props.width ? `${props.width}` : '100%')};
            height:${(props) => (props.height ? `${props.height}` : '48px')};
    }
    .status{
        position:absolute;
        &.error{
            color:#E61F00;
        }
    }
    textarea{
        resize:none;
    }
`
export default InputWrapper