import Styled from 'styled-components';

// const BodyWrapper = Styled.div`
// body, html {
//     height: 100%;
//     margin: 0;
//   }
  
//   .container {
//     width: 100%;
//   }
  
//   .topleft {
//     position: absolute;
//     top: 0;
//     left: 16px;
//   }
  
//   .bottomleft {
//     position: absolute;
//     bottom: 0;
//     left: 16px;
//   }
  
//   .middle {
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     text-align: center;
//   }
  
//   hr {
//     margin: auto;
//     width: 40%;
//   }

// `
const BodyWrapper = Styled.div`
  background-color:#eef7a6;
  padding:76px 0;
  img{
      max-width:100%
  }
  h1{
    font-size:56px;
      color:#000 ;
  }
  p{
    color:#000 ;
    font-size:20px;
    margin-bottom:0;
  }
  .items-center{
    display: flex;
      align-items: center
  }

`

export default BodyWrapper;