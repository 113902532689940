import Styled from 'styled-components';
const ContactWrapper = Styled.div`
  .map-img{
    width: 70%;
    height: 600px;
    object-fit: cover;
  }
  .contact-form{
    input, textarea{
      margin-bottom: 10px;
    }
  }
`
export default ContactWrapper;