import React from 'react'
import { Container } from 'react-bootstrap'
import { Box, Link, Grid, List, ListItemText } from "@mui/material"
import WhiteLogo from '../../static/images/logo-samp.png'
import ListWrapper from './style'
const Index = () => {
    const content = [
        {
            title: "Introduction",
            href: "#introduction"
        },
        {
            title: " Meaning of Terms ",
            href: "#meaningOfTerms"
        },
        {
            title: "About Us",
            href: "#aboutUs"
        },
        {
            title: "Use of our Website and App",
            href: "#ourWebsite"
        },
        {
            title: " Account Details",
            href: "#accountDetails"
        },
        {
            title: "Privacy Statement",
            href: "#privacyStatement"
        },
        {
            title: "Selling Your Item(s)",
            href: "#sellingItems"
        },
        {
            title: "The Contract",
            href: "#contract"
        },
        {
            title: "Trades",
            href: "#trades"
        },
        {
            title: "Your Cancellation Rights",
            href: "#cancellationRight"
        },
        {
            title: "Cancellation by Us",
            href: "#cancellationByUs"
        },
        {
            title: " Delivery Services",
            href: "#deliveryServices"
        },
        {
            title: " Responsibility and Title",
            href: "#responsibilityAndTitle"
        },
        {
            title: "Restrictions",
            href: "#restrictions"
        },
        {
            title: " Problems with Packages",
            href: "#problemsPackages"
        },
        {
            title: "Quality Assessment",
            href: "#qualityAssessment"
        },
        {
            title: "Liability",
            href: "#liability"
        },
        {
            title: "Transaction Times",
            href: "#transaction"
        },
        {
            title: "Payments",
            href: "#payments"
        },
        {
            title: " Special Offers, Survey and Competitions",
            href: "#specialoffers"
        },
        {
            title: " . Any Competition and these Rules will be governed by the laws of England and Wales and you are responsible for ensuring you are free to enter. Third Party Service Providers",
            href: "#competition"
        },
        {
            title: "Changes to Legal Notices",
            href: "#notices"
        },
        {
            title: "Law, Jurisdiction and Language",
            href: "#jurisdiction"
        },
        {
            title: "Invalidity",
            href: "#invalidity"
        },
        {
            title: "Third Party Rights",
            href: "#thirdPartyRights"
        },
        {
            title: "Assignment",
            href: "#assignment"
        },
        {
            title: "Unwanted Christmas Gifts - 10% EXTRA promotion",
            href: "#unwanted"
        },
    ]
    
    return (
        <>
            <Container>
                <Box textAlign="center" px={4} my={7}>
                    <Box component="h1" mb={4} fontSize="60px">Terms and Conditions</Box>
                    <Box compoent="h2" fontSize="24px" fontWeight="600">Please Read Properly As We Will Not Be Held Liable For Any Breach</Box>
                </Box>
            </Container>
            <Box bgcolor="#eef7a6">
                <Container>
                    <Box py={6} textAlign="center">
                        <img src={WhiteLogo} alt="no-logo" />
                        <Box compoent="h2" mt={2} fontSize="32px" fontWeight="700">We Care.</Box>
                        <Box compoent="p" pt={3} fontSize="20px" >CONTENT:</Box>
                        <Box mt={3}>
                            {content.map((list, key) => {
                                return (
                                    <Link fontSize="20px" mb={3} color="inherit" display="block" key={key} href={list.href}>{key + 1}.{list.title}</Link>
                                )
                            })}
                        </Box>
                        <Box>
                            <Grid container justifyContent="center"
                                alignItems="center">
                                <Grid item md={8}>
                                    <ListWrapper>
                                        <Box textAlign="left" my={8}>
                                            <Box fontSize="28px" component="h3" color="#000" id="introduction">
                                                1. Introduction
                                            </Box>
                                            <ul>
                                                <li >
                                                    <strong>1.1. What these terms cover.</strong>
                                                    These terms tell you who we are, how we process your trade (including what items
                                                    we accept and reject, and what happens if we reject them), how you and we may change or end the contract,
                                                    what to do if there is a problem and other important.
                                                </li>
                                                <li >
                                                    <strong>1.2. What these terms cover.</strong>
                                                    Please read these terms carefully before you submit your trades and orders to us.
                                                    These terms tell you who we are, how we will purchase items from you,
                                                    how we will provide services to you, how you and we may change or end the contract,
                                                    what to do if there is a problem and other important information.
                                                    If you think that there is a mistake in these terms or require any changes, please contact us to discuss.
                                                </li>
                                            </ul>
                                        </Box>
                                        <Box textAlign="left" my={8}>
                                            <Box fontSize="28px" component="h3" color="#000" id="meaningOfTerms">
                                                2. Meaning of Terms
                                            </Box>
                                            <ul>
                                                <li >
                                                    <Box><strong>2.1. In these Terms, the following words will have the following meanings:</strong></Box>
                                                    <Box mb={2} mt={2}> <strong className='mb-3'>"App"</strong>
                                                        means the FindMyBooks mobile application on which you may sell your Items to us;</Box>
                                                    <Box mb={2} mt={2}>   <strong className='mb-3'>"Authorised Seller"</strong>
                                                        is a business, organisation, co-operative or trader (whether sole, market or otherwise)
                                                        which has written permission from us to sell their Item(s) to us;</Box>
                                                    <Box mb={2} mt={2}>   <strong className='mb-3'>"Contract"</strong>
                                                        means the contract formed between you and us as set out in these Terms for the sale, recycling, delivery or return of your Item, as applicable;</Box>
                                                    <Box mb={2} mt={2}>   <strong className='mb-3'>"Delivery Services"</strong>
                                                        means the Drop Off or Collection Services we provide to you in respect of your Item(s) as set out in clause 12.</Box>
                                                    <Box mb={2} mt={2}>   <strong className='mb-3'>"Item"</strong>
                                                        means each item which you wish to sell to us in accordance with these Terms; these can be Books, CDs,
                                                        DVDs, Blu-Rays, Lego, Audio Books, Video Games and Video Games Accessories, and any other
                                                        items we may include from time to time as set out on our Website or our App;</Box>
                                                    <Box mb={2} mt={2}>   <strong className='mb-3'>"Item Condition Guidelines"</strong>
                                                        means our guidelines as to the acceptable condition that your Item(s) must be in to pass our Quality Assessment;</Box>
                                                    <Box mb={2} mt={2}>   <strong className='mb-3'>"Item Price"</strong>
                                                        means the amount at which we would be willing to buy your Items, as provided on our Website or App, and subject to the Quality Assessment;</Box>
                                                    <Box mb={2} mt={2}>   <strong className='mb-3'>"Minimum Trade Total"</strong>
                                                        means the minimum Trade value required for us to accept your Item(s) which can be found on our Website or App;</Box>
                                                    <Box mb={2} mt={2}>   <strong className='mb-3'>"Trade"</strong>
                                                        a trade by you to sell your Items to us for the Item Price;</Box>
                                                    <Box mb={2} mt={2}>   <strong className='mb-3'>"Trade Confirmation"</strong>
                                                        means the email from us that confirms that your Trade has been accepted by us;</Box>
                                                    <Box mb={2} mt={2}>   <strong className='mb-3'>"Package"</strong>
                                                        means the package, parcel or box that you send your Item(s) to us in;</Box>
                                                    <Box mb={2} mt={2}>   <strong className='mb-3'>"Quality Assessment"</strong>
                                                        means the process whereby we assess whether your Item(s) comply with our Item Condition Guidelines;</Box>
                                                    <Box mb={2} mt={2}>   <strong className='mb-3'>"Us / We / Our"</strong>
                                                        means Find My Books Ltd;</Box>
                                                    <Box mb={2} mt={2}>   <strong className='mb-3'>"Website"</strong>
                                                        means www.findmybooks.co.uk and also includes any other web-services on which you may sell your Items to us;</Box>
                                                    <Box mb={2} mt={2}>   <strong className='mb-3'>"Working Day"</strong>
                                                        a day other than a Saturday, Sunday or public holiday in England when banks in London are open for business;</Box>
                                                    <Box mb={2} mt={2}>   <strong className='mb-3'>"You / Your"</strong>
                                                        means you, the person using the Website or App, and sending an Item / Items to us to be purchased by us or recycled as set out in these Terms.</Box>

                                                </li>
                                                <li >
                                                    <strong>1.2. What these terms cover.</strong>
                                                    Please read these terms carefully before you submit your trades and orders to us. These terms tell you who we are, how we will purchase items from you,
                                                    how we will provide services to you, how you and we may change or end the contract, what to do if there is a problem and other important information. If you think that there is a mistake in these terms or require any changes, please contact us to discuss.
                                                </li>
                                            </ul>
                                        </Box>
                                        <Box textAlign="left" my={8}>
                                            <Box fontSize="28px" component="h3" color="#000" id="aboutUs">
                                                3. About Us
                                            </Box>
                                            <ul className='list-style-disc'>
                                                <li >
                                                    3.1. We are Find My Books Ltd, a company registered in England and Wales. Our company registration number is 14269192 and our registered office is at Unit A, 82 James Carter Road, Mildenhall, Suffolk, IP28 7DE United Kingdom.
                                                </li>
                                                <li >
                                                    3.2. If you have any queries about these Terms or if you have any comments or complaints on or about our Website or App, you can contact us at info@findmybooks.co.uk, write to us at the above address or by telephoning our customer service team at 01615099417.
                                                    If we have to contact you, we will do so by telephone or writing to you at the email address or postal address you provided to us in your Trade.</li>
                                                <li >
                                                    3.3.<strong>if you do not agree with these Terms, please do not use our Website or App or access any services made available through the Website or App.</strong></li>
                                            </ul>
                                        </Box>
                                        <Box textAlign="left" my={8}>
                                            <Box fontSize="28px" component="h3" color="#000" id="ourWebsite">
                                                4. Use of our Website and App
                                            </Box>
                                            <ul className='list-style-disc'>
                                                <li >
                                                    3.1. <starong>The Website and the App are for use by members of the general public only and not by businesses, organisations, co-operatives or traders (whether sole, market or otherwise).</starong>. If you are a business, organisation, co-operative or trader (whether sole, market or otherwise) you can contact us and seek permission to become an Authorised Seller. We reserve the right not to pay for any Trades received from businesses, organisations, co-operatives or traders (whether sole, market or otherwise) who are not Authorised Sellers. In such circumstances if we suspect a Trade is from a non-Authorised Seller we will email the seller of this advising them that their Item(s) will be kept for fourteen (14) days so that they can arrange collection of their Item(s) during our business hours which can be found on our Website.
                                                </li>
                                                <li >
                                                    4.2.  Our Website and our App are solely for use in the UK. Unfortunately, we do not accept Item(s) from customers outside of the UK.</li>
                                                <li >
                                                    4.3. By making a Trade through the Website or App, you are confirming to us that:
                                                    <ul className='list-style-circle'>
                                                        <li >

                                                            a. You are an individual consumer who is legally capable of entering into binding contracts;

                                                        </li>
                                                        <li >

                                                            b. You are the legal owner or have the permission of the legal owner to sell Items to us;

                                                        </li>
                                                        <li >

                                                            c. You are at least 18 years of age or, if you are under 18 years of age, you have obtained your parent’s or guardian’s consent to sell your Items to us for the valuation indicated on the Website or App;

                                                        </li>
                                                        <li >

                                                            d. you are based in the UK or Channel Islands;

                                                        </li>
                                                        <li >

                                                            e. You are accessing the Website or App from the UK or Channel Islands;

                                                        </li>
                                                        <li >

                                                            f. You are accessing the Website or App from the UK or Channel Islands;

                                                        </li>
                                                        <li > 3.g. You have read and understood our <Link href="/items-guideline" color="#0000FF">Item Condition Guidelines</Link> and</li>
                                                        <li>3.h. You have read and understood our  <Link href="/packaging-guide" color="#0000FF">Packaging Guidelines</Link></li>
                                                    </ul>
                                                </li>
                                                <li >
                                                    4.4. By creating a Trade through the Website or App you agree and expressly warrant that prior to selling your Item(s) (including but not limited to CDs, DVDs, Blu-Rays, Video Games, Games and Toys) have not been digitally copied and you have not made any other type of illegal copy of the entertainment products that you are selling and furthermore <strong>you agree and accept to fully indemnify us against any potential action or action(s) whatsoever</strong>that may or may not be taken with regard to the sale of your entertainment products in relation to, but not limited to, artist rights, copyright and/ or intellectual property that might or may result from the sale of your entertainment products to us.</li>
                                            </ul>
                                        </Box>
                                        <Box textAlign="left" my={8}>
                                            <Box fontSize="28px" component="h3" color="#000" id="accountDetails">
                                                5. Account Details
                                            </Box>
                                            <ul className='list-style-disc'>
                                                <li >
                                                    5.1. When creating your account with us, you will need to provide an email address and set up a password. You must keep your password secure and must not disclose it or share it with anyone.
                                                </li>
                                                <li >
                                                    5.2. You are responsible for keeping your account details safe and secure. If you forget your password please click the "Lost password" link where you will be able to reset your password.
                                                </li>
                                                <li >
                                                    5.3. You may only have one account per person. If you are found to have more than one account, we shall be entitled to immediately terminate your accounts and pending Trades. We may charge you a reasonable administration fee based on the costs incurred by us in doing so. If we find you are abusing or manipulating the Website or App or services we Trade, we shall be entitled to recoup our losses from any payments owing on quality checked trades. We shall also be entitled to immediately terminate your accounts and cancel any pending Trades.
                                                </li>
                                                <li >
                                                    5.4. If you know or suspect that anyone other than you knows your user ID or password, you must promptly notify us.
                                                </li>
                                            </ul>
                                        </Box>
                                        <Box textAlign="left" my={8}>
                                            <Box fontSize="28px" component="h3" color="#000" id="#privacyStatement">
                                                6. Privacy Statement
                                            </Box>
                                            <ul className='list-style-disc'>
                                                <li >
                                                    You acknowledge and agree to be bound by the terms of our Privacy Statement.
                                                </li>
                                            </ul>
                                        </Box>
                                        <Box textAlign="left" my={8}>
                                            <Box fontSize="28px" component="h3" color="#000" id="sellingItems">
                                                7.  Selling Your Item(s)
                                            </Box>
                                            <ul className='list-style-disc'>
                                                <li >
                                                    7.1. If you want to sell your Item(s) to us through our Website or our App you should:
                                                    <ul className='list-style-circle'>
                                                        <li >a. Enter the barcode or ISBN number of the Item(s) that you want to sell;</li>
                                                        <li >b. we will then give you price(s) for your Item(s) within an hour.</li>
                                                        <li >c. You can add a number of Items to your selling basket and once you are happy with the Item(s) you have agreed to supply at the Item Price(s)
                                                            and, provided that you have met the Minimum Trade Total, click on 'Sell My Items';</li>
                                                        <li >d. You will then be asked to confirm that you have accepted these Terms;1.d)
                                                            You will then be asked to confirm that you have accepted these Terms;</li>
                                                        <li >e. You will then be asked to register an account or asked to sign in to your existing account;1.e)
                                                            You will then be asked to register an account or asked to sign in to your existing account;</li>
                                                        <li >f. You will then select how you wish to be paid and choose how to send your Item(s) to us; and</li>
                                                        <li >g. Finally you will click 'Accept Trade</li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </Box>
                                        <Box textAlign="left" my={8}>
                                            <Box fontSize="28px" component="h3" color="#000" id="contract">
                                                8.The Contract
                                            </Box>
                                            <p>By clicking ‘Accept Trade you are offering to supply your Item(s) to us for the accepted price
                                                when creating your Trade and to us providing you with any related Delivery Services to courier your Item(s) to us.</p>
                                            <p>All Trade are subject to acceptance by us. We do not have to accept any Trade. We will confirm our conditional acceptance to you in writing by sending you a Trade
                                                Confirmation that confirms that your Trade has been processed. This is usually sent immediately..</p>
                                            <p>Your Item(s) should be sent to us within seven (7) days (unless otherwise mutually agreed) of receiving the Trade Confirmation. After this time has elapsed, your Trade may be cancelled, our Order Confirmation withdrawn, and your Item(s) may need to be re-listed.</p>
                                            <ul className='list-style-disc'>
                                                <li >
                                                    8.1. Please note that payment for your Item(s) is conditional on your Item(s) passing our Quality Assessment.
                                                </li>
                                            </ul>
                                            <p>If your Item(s) passes our Quality Assessment we will pay you the Item Price for that (those) Item(s) as set out in our Trade.</p>
                                            <p>Nothing in these Terms shall affect our right to revise the Trade for your Item(s) if it does not pass our Quality Assessment.</p>
                                        </Box>
                                        <Box textAlign="left" my={8}>
                                            <Box fontSize="28px" component="h3" color="#000" id="trades">
                                                9. Trades
                                            </Box>
                                            <p>For all Item(s), the Offer given to you by us is valid for twenty-one (21) days from the date that you create your Trade, subject to your Item(s) passing our Quality Assessment.</p>
                                            <p>Any Item Price or Order Confirmation that we provide to you is our property. You must only use the Website or App's valuation service to value Items that you own and are considering selling to us.</p>

                                        </Box>
                                        <Box textAlign="left" my={8}>
                                            <Box fontSize="28px" component="h3" color="#000" id="cancellationRight">
                                                10. Your Cancellation Rights
                                            </Box>
                                            <p>You have a legal right to cancel your Contract with us at any time, without giving any reason, within fourteen (14) days beginning on the day after your Contract with us is formed (this is the date of the Trade Confirmation).</p>
                                            <p><strong>It is important to note that the 14-day period during which you can cancel your Contract will come to an end when we begin to perform the Delivery Services to fulfil the Contract i.e when you hand over your Item(s) at a drop off point or our courier has collected your Item(s).</strong></p>
                                            <p>If you do wish to exercise your cancellation rights, you must contact us and tell us in writing that you want to do so within the fourteen (14) day period from receiving the Trade Confirmation. To exercise these rights please contact us at info@findmybooks.co.uk.</p>
                                            <p>If you inform us that you no longer wish to send an Item(s), we will remove the relevant Item(s) and your Trade from our system so that we know you will not be sending your Item(s). In those circumstances, you will not be entitled to any payment from us whatsoever as to those Items.</p>
                                        </Box>
                                        <Box textAlign="left" my={8}>
                                            <Box fontSize="28px" component="h3" color="#000" id="cancellationByUs">
                                                11. Cancellation by Us
                                            </Box>
                                            <ul className='list-style-disc'>
                                                <li >
                                                    11.1. We reserve the right to cancel or amend (at our option) the Contract between us if:
                                                    <ul className='list-style-circle'>
                                                        <li >a. The items you send do not pass our Quality Assessment; or</li>
                                                        <li >b. You are not or we have reason to believe you are not the legal owner of the Item(s); or</li>
                                                        <li >d. One or more of the Items you traded was listed at an incorrect price due to a typographical error or an error in the pricing information stated by us; or</li>
                                                        <li >e. The Items received are not the same as the Items specified at the time of the initial Trade.</li>
                                                    </ul>
                                                </li>
                                            </ul>
                                            <p>If we do cancel your Contract, we will notify you by e-mail.</p>
                                            <p>Where we suspect your Item(s) include stolen goods, we will investigate thoroughly and where appropriate will co-operate with the police.</p>
                                            <p>If the cancellation is due to our error, we will send your Item(s) back to you at our expense.
                                            </p>
                                            <p>If the cancellation is due to us receiving additional Item(s) or Item(s) that fail our Quality Assessment you will be informed of this by email and given 5 working days to either:</p>
                                            <ul className='list-style-square'>
                                                <li >a. Arrange a collection of your Item(s) within our business hours which can be found on our Website;</li>
                                                <li >b. Pay for the return of the Item(s); or</li>
                                                <li >c. With your prior agreement, arrange disposal of you Item(s), and your Contract will be amended accordingly.</li>
                                            </ul>
                                            <ul className='list-style-disc'>
                                                <li >
                                                    11.2. If we do not hear from you within five (5) working days of our notice to you of the failure of an Item, any Item that fail our or Quality Assessment will be recycled by us in a responsible manner and you will not be paid for them.
                                                </li>
                                            </ul>
                                        </Box>
                                        <Box textAlign="left" my={8}>
                                            <Box fontSize="28px" component="h3" color="#000" id="deliveryServices">
                                                12. Delivery Services
                                            </Box>
                                            <ul className='list-style-disc'>
                                                <li >
                                                    12.1. When you process your Trade with us, you can choose how to send your Item(s) to us from the following services ("Delivery Services"):
                                                    <ul className='list-style-circle'>
                                                        <li >
                                                            1.a. Drop Off Service: using this service you can take your Item(s) to one of our chosen carriers drop off points who will send your Item(s) to us for free. Details of your nearest drop off point can be found on our Website or our App;
                                                        </li>
                                                        <li >
                                                            1.b. Collection Service: using this service you can arrange for our carrier to collect your Item(s) from your home or workplace who will deliver your Item(s) to us for free.
                                                        </li>
                                                        <li >
                                                            1.c. Premium Service: details of any premium delivery service we trade can be found on our Website or our App from time to time.
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li >
                                                    12.2. If your Item(s) does not pass our Quality Assessment or any Item(s) that have been sent to us in error can be returned to you at your expense as set out in the Return Postage Costs below:
                                                    <Box mt={3}>
                                                        <table>
                                                            <thead className='terms-table-header'>
                                                                <tr>
                                                                    <td>
                                                                        <Box className="text">CD</Box>
                                                                    </td>
                                                                    <td>
                                                                        <Box className="text">DVD</Box>
                                                                    </td>
                                                                    <td>
                                                                        <Box className="text">GAME</Box>
                                                                    </td>
                                                                    <td>
                                                                        <Box className="text">BOOK*</Box>
                                                                    </td>
                                                                </tr>
                                                            </thead>
                                                            <tbody className='terms-table-body'>
                                                                <tr>
                                                                    <td>£1.00</td>
                                                                    <td>£1.00</td>
                                                                    <td>£1.00</td>
                                                                    <td>£2.40</td>
                                                                </tr>

                                                            </tbody>
                                                        </table>
                                                    </Box>
                                                </li>
                                                <li >
                                                    12.3. Please note that the Return Postage Costs for any Item(s) over 1KG may be more and you will be informed of this in advance.
                                                </li>
                                            </ul>
                                        </Box>
                                        <Box textAlign="left" my={8}>
                                            <Box fontSize="28px" component="h3" color="#000" id="responsibilityAndTitle">
                                                13. Responsibility and Title
                                            </Box>
                                            <p>Title (or ownership) of the Item(s) transfers to us as soon as we receive the Item(s) at our warehouse as delivered by the relevant carrier. </p>
                                            <p>If you use our Drop Off Service, risk (or responsibility) for the care of the Item(s) will transfer to us when you hand your Item(s) over at our chosen carriers drop off point.</p>
                                            <p>If you use our Collection Service, risk (or responsibility) for the care of the Item(s) will transfer when you hand your Item(s) over to the carrier's representative.</p>
                                            <p>If damage or loss occurs as a result of badly packaged items not conforming to our Packaging Guidelines then that remains your responsibility.</p>
                                            <p>We therefore strongly recommend you read our <Link href="/packaging-guide"> Packaging Guidelines which can be found here.</Link></p>
                                        </Box>
                                        <Box textAlign="left" my={8}>
                                            <Box fontSize="28px" component="h3" color="#000" id="restrictions">
                                                14. Restrictions
                                            </Box>
                                            <p>The Delivery Services may only be used for Items that you are selling to us. You may not use these Delivery Services for any other items or purpose.</p>
                                            <p>We and our delivery partners accept no liability whatsoever for any losses or harm arising from carriage of any Restricted Items provided by or on behalf of you.</p>
                                            <p>
                                                You acknowledge and understand that we have a number of legal obligations to comply with in respect of the carriage of Items, including but not limited to an obligation not to carry or accept for carriage Items that are deemed to be “Dangerous Goods” as defined in the Packaging and Labelling of Dangerous Substances Regulations 1984, the Classification, Packaging and Labelling Regulations 1983, the Radioactive Material (Road Transport) (Great Britain) Regulations 1996 and the Carriage of Explosives by Road Regulations 1996.</p>
                                            <p>You will be liable for any loss, damage or liability arising from breach of these legal obligations as a result of transportation of your Package or any other item you use or attempt to use our Delivery Services to send.</p>
                                            <p>Should you (knowingly or unknowingly) attempt to require or require us or our carriers to carry any Item(s) or handle any other Item(s) as detailed, we and our chosen carriers shall be under no liability whatsoever in connection with those items, howsoever arising.</p>
                                        </Box>
                                        <Box textAlign="left" my={8}>
                                            <Box fontSize="28px" component="h3" color="#000" id="problemsPackages">
                                                15. Problems with Packages

                                            </Box>
                                            <p>It is your responsibility to obtain a receipt from the carrier and retain this for tracking purposes. If you cannot provide a valid receipt for the Package you wish to claim for then we may not be able to claim for any loss or damage to your Package.</p>
                                            <p>It is your responsibility to ensure the correct goods are sent to us. In addition to the Return Postage Costs we reserve the right to charge an administration fee as we feel appropriate if items other than what is expected are received.</p>
                                        </Box>
                                        <Box textAlign="left" my={8}>
                                            <Box fontSize="28px" component="h3" color="#000" id="qualityAssessment">
                                                16. Quality Assessment

                                            </Box>
                                            <p>Our decision is final where items fail our Quality Assessment. Please note that we may supply photos of the rejected Item(s), but whether we do so will be at our discretion. <Link href="/packaging-guide">Our Item Condition Guidelines can be found here for further information.</Link></p>
                                        </Box>
                                        <Box textAlign="left" my={8}>
                                            <Box fontSize="28px" component="h3" color="#000" id="liability">
                                                17. Liability

                                            </Box>
                                            <p>We are responsible to you for foreseeable loss and damage caused by us. If we fail to comply with these Terms, we are responsible for loss or damage you suffer that is a foreseeable result of our breaking of this Contract or our failing to use reasonable care and skill, but we are not responsible for any loss or damage that is not foreseeable. Loss or damage is foreseeable if either it is obvious that it will happen or if, at the time the Contract was made, both we and you knew it might happen, for example, if you discussed it with us during the Trade process. We limit our liability in relation to any Item to the Item Price for that Item, and we limit our liability in relation to any Contract to the total Item Price(s) in that Contract</p>
                                            <p>We do not exclude or limit in any way our liability to you where it would be unlawful to do so. This includes liability for death or personal injury caused by our negligence or the negligence of our employees, agents or subcontractors; for fraud or fraudulent misrepresentation; for breach of your legal rights, you might have as a consumer under applicable local law or other statutory rights that may not be excluded.</p>
                                            <p>We are not liable for business losses. If our Website or App is used for any commercial or business purpose we will have no liability for any loss of profit, loss of business, business interruption, or loss of business opportunity.</p>
                                            <p>We are not liable for any consequential losses howsoever caused or foreseeable.</p>
                                            <p>While we will use reasonable endeavours to verify the accuracy of any information we place on the Website or the App, we make no warranties, whether express or implied in relation to its accuracy. The Website and the App are provided on an “as is” and “as available” basis without any representation or endorsement made and we make no warranties of any kind, whether express or implied, in relation to the Website or App, or any transaction that may be conducted on or through the Website or the App including but not limited to, implied warranties of non-infringement, compatibility, security, accuracy, conditions of completeness, or any implied warranty arising from course of dealing or usage or trade.</p>
                                            <p>We make no warranty that the Website or the App will meet your requirements or will be uninterrupted, timely or error-free, that defects will be corrected, or that the site or the server that makes it available are free of viruses or bugs or represents the full functionality, accuracy and reliability of the Website or the App. We will not be responsible or liable to you for any loss of content or material uploaded or transmitted through the Website or the App.</p>
                                            <p>You must observe and comply with all applicable regulations and legislation, including obtaining all necessary customs, import or other permits to trade goods to us on our site. The importation or exportation of certain goods to you may be prohibited by certain national laws. We make no representation and accept no liability in respect of the export or import of the goods you trade.</p>
                                            <ul className='list-style-disc'>
                                                <li>17.1. Our Website and our App may contain links to other websites. We are not responsible for the content, accuracy or opinions express in such websites, and such websites are not investigated, monitored or checked for accuracy or completeness by us. Inclusion of any linked website on or through the Website or the App does not imply approval or endorsement of the linked website by us. If you decide to leave the Website or App and access these third-party sites, you do so at your own risk.</li>
                                            </ul>
                                            <Box textAlign="left" my={8}>
                                                <Box fontSize="28px" component="h3" color="#000" id="transaction">
                                                    18. Transaction Times

                                                </Box>
                                                <ul className='list-style-disc'>
                                                    <li >18.1. No warranty or guarantee is given as to the time taken to complete the transaction. We will make reasonable endeavours to complete the process in a fair timescale. As guide to helping you understand the timescales involved:
                                                        <ul className='list-style-circle'>
                                                            <li >18.1.a Where you use our Drop Off Service or Collection Service, Item(s) can take up to five (5) working days to arrive at our warehouse. We take no responsibility for delays in the service provided by our chosen delivery partners.
                                                            </li>
                                                            <li >18.1.b Details of any time scales for any premium delivery service we are able to trade will be set out on our Website or our App from time to time.</li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </Box>
                                            <Box textAlign="left" my={8}>
                                                <Box fontSize="28px" component="h3" color="#000" id="payments">
                                                    19. Payments


                                                </Box>
                                                <ul className='list-style-disc'>
                                                    <li>19.1. We strive to make payment as follows but it cannot always be guaranteed:
                                                        <ul className='list-style-circle'>
                                                            <li >19.1.a Payment is made by your chosen method on the same business day for Prime-Account Members for any Trades at status Checked and Awaiting Payment status before 3pm.

                                                            </li>
                                                            <li >19.1.b Payment will be made during the following business day for non-Prime-Account trade finalised after 1pm. Please note that at peak times, this may take a little longer.</li>
                                                            <li >19.1c. Please note that it is the responsibility of the user to ensure that payment details and addresses that are provided are accurate. If as a result of incorrect payment data the payment is made to the wrong party and the money cannot be recovered then the loss is the responsibility of the user. Under such circumstances if payment funds are recovered, we reserve the right to make a reasonable administration charge.</li>
                                                            <li >19.1.d Prime-Account Member cheques are posted first class and Non Prime-Account Member cheques are posted second class and may take up to 7 working days to be delivered.</li>
                                                            <li >19.1.d Prime-Account Member cheques are posted first class and Non Prime-Account Member cheques are posted second class and may take up to 7 working days to be delivered.
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </Box>
                                            <Box textAlign="left" my={8}>
                                                <Box fontSize="28px" component="h3" color="#000" id="specialoffers">
                                                    20. Special Offers, Survey and Competitions


                                                </Box>
                                                <ul className='list-style-disc'>
                                                    <li >20.1. We regularly have special offers, surveys and competitions available on our Website and our App. You should refer to the specific terms of any such offering.
                                                    </li>
                                                    <li >
                                                        20.2. For competitions we offer (Competitions), when you take part in any Competition, the following terms will apply (Rules):
                                                        <ul className='list-style-circle'>
                                                            <li >2.a. We will be the promoter of any Competition. We assume (and you warrant) that by entering any Competition you have legal capacity to enter that Competition and you are not prohibited from entering by any local or national law or regulation. By entering a Competition, you agree to be bound by these Rules.</li>
                                                            <li >2.b. We reserve the right to cancel any Competition or amend these Rules at any time without prior notice, and our decision shall be final in the event of any dispute.</li>
                                                            <li >2.c. The requirements to enter each the Competition will be set out before you can enter it.
                                                            </li>
                                                            <li >2.d. We reserve the right to disqualify you from any Competition if we have reasonable grounds to believe you have breached any of the Rules, or if your conduct is contrary to the spirit or intention of the relevant Competition.</li>
                                                            <li >2.e. Entry to any Competition is free but you should be aware that you may be subject to charges depending on your own individual arrangements for internet access.</li>
                                                            <li >2.f. The prize for our Competitions will be set out on the Website or the App, and you will be made aware of the prize before entering a Competition. There is no cash alternative, and the prize is non-transferable.</li>

                                                            <li >2.g. We cannot accept any responsibility for any damage, loss, injury or disappointment suffered by you entering a Competition or as a result of acceptance or non-acceptance of any prize.</li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </Box>
                                            <Box textAlign="left" my={8}>
                                                <Box fontSize="28px" component="h3" color="#000" id="competition">
                                                    21. Any Competition and these Rules will be governed by the laws of England and Wales and you are responsible for ensuring you are free to enter. Third Party Service Providers
                                                </Box>
                                                <ul className='list-style-disc'>
                                                    <li >21.1. We employ other companies and individuals to perform functions on our behalf. They have access to personal information needed to perform their functions, but may not use it for other purposes. Further, they must process the personal information in accordance with our Privacy Statement and as permitted by applicable data protection laws.
                                                    </li>
                                                    <li >
                                                        21.2. We use third parties to:
                                                        <ul className='list-style-circle'>
                                                            <li >2.a. Ask you to review our service.</li>
                                                            <li >2.b. Send you survey questionnaires.</li>
                                                            <li >2.c. Send triggered and promotional emails.
                                                            </li>
                                                        </ul>
                                                        <li >21.3. Please note that you can change your communication preferences at any time in the ‘My Account’ section of our Website or our App.
                                                        </li>
                                                    </li>
                                                </ul>
                                            </Box>
                                            <Box textAlign="left" my={8}>
                                                <Box fontSize="28px" component="h3" color="#000" id="notices">
                                                    22. Changes to Legal Notices
                                                </Box>
                                                <ul className='list-style-disc'>
                                                    <li >22.1. We reserve the right to change these Terms from time to time and you should look through them each time you make a new Trade.</li>
                                                </ul>
                                            </Box>
                                            <Box textAlign="left" my={8}>
                                                <Box fontSize="28px" component="h3" color="#000" id="jurisdiction">
                                                    23. Law, Jurisdiction and Language

                                                </Box>
                                                <ul className='list-style-disc'>
                                                    <li >23.1. The website and the App, any content contained therein and any contract brought into being as a result of usage the Website or the App are governed by and construed in accordance with English law. Parties to any such contract agree to submit to the exclusive jurisdiction of the courts of England and Wales. All contracts are concluded in English.
                                                    </li>
                                                </ul>
                                            </Box>
                                            <Box textAlign="left" my={8}>
                                                <Box fontSize="28px" component="h3" color="#000" id="invalidity">
                                                    24. Invalidity

                                                </Box>
                                                <p>Each of the paragraphs of these Terms operates separately. If any court or relevant authority decides that any part of these Terms are unenforceable (including any provision in which we exclude our liability to you) the enforceability of any other part of these Terms will not be affected.
                                                    Our failure to exercise or enforce any right or provision of these Terms shall not constitute a waiver of such right or provision in that or any other instance.</p>
                                            </Box>
                                            <Box textAlign="left" my={8}>
                                                <Box fontSize="28px" component="h3" color="#000" id="thirdPartyRights">
                                                    25. Third Party Rights

                                                </Box>
                                                <ul className='list-style-disc'>
                                                    <li>
                                                        25.1. Nothing in this Agreement is intended to, nor shall it confer any rights on a third party.
                                                    </li>
                                                </ul>
                                            </Box>
                                            <Box textAlign="left" my={8}>
                                                <Box fontSize="28px" component="h3" color="#000" id="assignment">
                                                    26. Assignment

                                                </Box>
                                                <ul className='list-style-disc'>
                                                    <li>
                                                        26.1. We may transfer our rights and obligations under these Terms to another organisation. We will ensure that the transfer will not affect your rights under the Contract.
                                                    </li>
                                                    <li>
                                                        <li>
                                                            26.1. We may transfer our rights and obligations under these Terms to another organisation. We will ensure that the transfer will not affect your rights under the Contract.
                                                        </li>
                                                    </li>
                                                </ul>
                                            </Box>
                                            <Box textAlign="left" my={8}>
                                                <Box fontSize="28px" component="h3" color="#000" id="unwanted">
                                                    27. Unwanted Christmas Gifts - 10% EXTRA promotion

                                                </Box>
                                                <ul className='list-style-disc'>
                                                    <li>
                                                        27.1. This offer can only be used on 5 different items per person/per account.
                                                    </li>
                                                    <li>
                                                        27.2. The offer will run from 26th December 2019 - 31st January 2020.
                                                    </li>
                                                    <li>

                                                        27.3. Unwanted Christmas presents must be in new or like new condition, preferably in their original packaging. Items that have been used will be reduced back to original packaging. Items that have been used will be reduced back to original value.
                                                    </li>
                                                    <li>

                                                        27.4. We reserve the right to determine if the item falls into the category of unwanted Christmas presents and gains the extra 10%.
                                                    </li>
                                                </ul>
                                                <p><strong>can't find an answer </strong>to my question above?</p>
                                                <p>Visit our contact us page and use our web form to submit your enquiry, one of our experts will be in contact to help you with your questions.</p>
                                                <Link >Contact Us</Link>
                                                <p>info@findmybooks.co.uk</p>
                                                <p>Address: Unit A, 82 James Carter Road, Mildenhall, Suffolk, IP28 7DE United Kingdom.</p>
                                            </Box>
                                        </Box>
                                    </ListWrapper>
                                </Grid>
                            </Grid>
                        </Box>
                        <img src={WhiteLogo} alt="no-logo" />
                    </Box>
                    
                    <Box>
          
                    </Box>
                </Container>
            </Box>
        </>

    )
}
export default Index
