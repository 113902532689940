import React, { useState } from 'react';
import Input from '../../component/input/index';
import Button from '../../component/button/index';
import { Col, Row } from 'react-bootstrap';
import {
  FileAddOutlined,
  DeleteOutlined
} from '@ant-design/icons';

import { useDispatch, useSelector } from 'react-redux';
import { DisposeBooks } from '../../redux/slice/authSlice';

const Index = () => {
  const dispatch = useDispatch();
  const [bookNumbers, setBookNumbers] = useState(['']);
  const [discription, setDiscription] = useState('');
  const [category, setCategory] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNo, setPhoneNo] = useState('');

  const handleAddBook = () => {
    setBookNumbers(prevNumbers => [...prevNumbers, '']);
  };

  const handleDeleteBook = (index) => {
    setBookNumbers(prevNumbers => prevNumbers.filter((_, i) => i !== index));
  };

  const handleBookChange = (index, value) => {
    setBookNumbers(prevNumbers => {
      const updatedNumbers = [...prevNumbers];
      updatedNumbers[index] = value;
      return updatedNumbers;
    });
  };

  const handleSubmit = () => {
    // Handle submission logic here
    const body = {
      email,
      phoneNo,
      bookNumbers,
      category,
      discription
    }
    console.log('body', body);
    dispatch(DisposeBooks(body)).then(() => {
      alert('Books information sent successfully');
    })
  };

  return (
    <div className='DisposeBooksContainer'>
      <h1 className='text-center mt-4'>Bulk Collection</h1>
      <Row>
        <Col xs={4}></Col>
        <Col xs={4} className='mb-4'>
          {bookNumbers.map((book, index) => (
            <>
            <div key={index}>
              <Input
                name={`bookNo${index + 1}`}
                label={`Book No ${index + 1} Quantity`}
                type="number"
                value={book}
                onChange={(e) => handleBookChange(index, e.target.value)}
              />
            </div>
            <div style={{display:'flex',justifyContent:'flex-end', gap: 4 }}>
                {index > 0 && <DeleteOutlined onClick={() => handleDeleteBook(index)} />}
                <FileAddOutlined  onClick={handleAddBook}/>  
            </div>
            </>
          ))} 
          <Input
            name="Book Categories"
            label="Book Categories"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          />
          <Input
            name="Email"
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Input
            name="Phone No"
            label="Phone No"
            type="number"
            value={phoneNo}
            onChange={(e) => setPhoneNo(e.target.value)}
          />
          <Input
            name="Discription"
            label="Additional Information"
            type="textarea"
            rows={4}
            value={discription}
            onChange={(e) => setDiscription(e.target.value)}
          />
          <Button onClick={handleSubmit}>Submit</Button>
        </Col>
        <Col xs={4}></Col>
      </Row>
    </div>
  );
};

export default Index;
