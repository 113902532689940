import Styled from 'styled-components';
const AccordionWrapper = Styled.div`
margin-bottom:32px;
.MuiPaper-root {
    box-shadow:none;
    border-top:1px solid #ccc;
    border-bottom:1px solid #ccc;
    border-radius:0!important;
}
`
export default AccordionWrapper;