import { configureStore, combineReducers } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';

import authSlice from '../slice/authSlice';
import getProductSlice  from '../slice/getProductSlice';
import promoCodesSlice from '../slice/promoCodesSlice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth']
};

const reducers = combineReducers({
  auth: authSlice,
  product:getProductSlice,
  promoCode: promoCodesSlice
});

const rootReducer = (state, action) => {
  if (action.type === 'auth/logout') {
    state = undefined;
  }
  return reducers(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default configureStore({
  reducer: persistedReducer,
  middleware: [thunk, logger],
  devTools: true
});
